import {ReactNode, useContext, useEffect, useMemo, useRef, useState} from 'react';
import './select.scss';
import Popup from '../popup';
import classNames from '../../lib/class-names';
import {MenuContext} from '../popup/context';
import {observer} from 'mobx-react';
import generateKeyHandler from '../popup/key-events';
import {HeaderContext} from '../header/context';
import {ComboBox, Dropdown} from '@carbon/react';

export type BasicSelectType = {
    value: string
    label: string
}

type Props<T> = {
    data: Array<T>
    value: string
    onChange: (opt: string) => void
    children: [(item: T, i: number) => string, (item: T, i: number) => ReactNode]
    searchable?: boolean
    disabled?: boolean
    optionDisabled?: (item: T, i: number) => boolean
    width?: number,
    defaultEmpty?: boolean,
    rightAlign?: boolean,
}

const Select = observer(function Select<T>(props: Props<T>) {
    let {data, value, onChange, searchable, disabled, optionDisabled, width, rightAlign} = props;
    optionDisabled = optionDisabled || (() => false);
    const [getValue, getLabel] = props.children;
    const menuStore = useContext(MenuContext);
    const [focusIndex, setFocus] = useState(-1);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [timeout, setSearchTimeout] = useState<any>(null); // Type is actually something like NodeJS.Timeout. But timeouts are actually numbers. Anyway, it's set to `any` to make the compiler happy
    const [search, setSearch] = useState<string>('');
    const {useNewDesign} = useContext(HeaderContext);

    const handleClick = (item: T, i: number) => {
        if (optionDisabled!(item, i)) return;
        onChange(getValue(item, i));
    };

    const [label, activeIndex] = useMemo(() => {
        let label: ReactNode = <span>&nbsp;</span>;
        let index = -1;
        for (let i = 0; i < data.length; i++) {
            if (getValue(data[i], i) === value) {
                label = getLabel(data[i], i);
                index = i;
                break;
            }
        }
        return [label, index];
    }, [value, data, getLabel, getValue]);

    // Add keyboard listener for keyboard interaction
    useEffect(() => {
        if (!menuStore.visible) setFocus(-1);
    }, [menuStore.visible]);

    // Focus on items based on the search string
    useEffect(() => {
        if (!search || !data.length || searchable) return;
        const index = data.findIndex((item, i) => {
            const display = props.children[1](item, i);
            const value: string = typeof display === 'string' ? display : props.children[0](item, i);
            return value
                .toLowerCase()
                .replace(/[^a-z0-9]/g, '')
                .startsWith(search);
        });
        if (index >= 0) setFocus(index);
    }, [data, onChange, props.children, search, searchable]);

    const items = useMemo(() => {
        const zipped: Array<[T, number]> = data.map((item, i) => [item, i]);
        if (!searchable || !search) return zipped;
        const query = search.toLowerCase();
        return zipped.filter(([item, i]) => {
            const display = props.children[1](item, i);
            const value: string = typeof display === 'string' ? display : props.children[0](item, i);
            return value.toLowerCase().includes(query);
        });
    }, [data, searchable, search, props.children]);

    const handleKey = generateKeyHandler(
        menuStore,
        focusIndex,
        data.length,
        setFocus,
        (i) => handleClick(data[i], i),
        setSearch,
        timeout,
        setSearchTimeout,
    );

    if (useNewDesign) {
        const DropdownElement = searchable ? ComboBox : Dropdown;
        return <DropdownElement 
            className={rightAlign ? 'right' : ''}
            disabled={disabled} 
            hideLabel 
            items={items} 
            label={label || ''} 
            id='select'
            titleText={label || ''} 
            itemToElement={(p) => <>{getLabel(p[0], p[1])}</>} 
            renderSelectedItem={(p) => <>{getLabel(p[0], p[1])}</>}
            onChange={(e) => e.selectedItem && onChange(getValue(e.selectedItem[0], e.selectedItem[1]))}
            onInputChange={(text) => setSearch(text)} />;
    }

    return (
        <div className='select-holder'>
            <button
                className={`select ${menuStore.visible && 'open'}`}
                style={{width: (width || '300') + 'px'}}
                ref={buttonRef}
                onKeyDown={handleKey}
                role='combobox'
                aria-controls='popup-menu'
                aria-expanded={menuStore.visible ? 'true' : 'false'}
                disabled={disabled}
            >
                {label}
            </button>
            <Popup targetRef={buttonRef} matchWidth scrollIndex={focusIndex}>
                {searchable ? (
                    <div className='select-search-holder'>
                        <div className='mx-2 mb-2'>
                            <input
                                type='text'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                                placeholder='Search Options'
                            />
                        </div>
                        <div className='select-search-items'>
                            {items.map(([item, i]) => (
                                <button
                                    key={i}
                                    onClick={() => handleClick(item, i)}
                                    className={classNames('menu-item', {
                                        'is-active': i === activeIndex,
                                        'is-focus': i === focusIndex,
                                        'is-disabled': optionDisabled!(item, i),
                                    })}
                                >
                                    {getLabel(item, i)}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (
                    items.map(([item, i]) => (
                        <button
                            key={i}
                            onClick={() => handleClick(item, i)}
                            className={classNames('menu-item', {
                                'is-active': i === activeIndex,
                                'is-focus': i === focusIndex,
                                'is-disabled': optionDisabled!(item, i),
                            })}
                        >
                            {getLabel(item, i)}
                        </button>
                    ))
                )}
            </Popup>
        </div>
    );
});

export default Select;

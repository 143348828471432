import Tabs from '../components/tabs/tabs';
import SpecialtyFilterTable from '../components/specialty-filter-table';
import ComplianceScore from '../components/compliance-score';
import NetworkMap from '../components/network-map';
import GeographyFilters from '../components/geography-filter';
import './timeDistanceAccessPage.scss';
import AdditionalProvidersTable from '../components/additional-providers-table';
import {Button, Layer, Modal} from '@carbon/react';
import ComplianceSummaryTable from '../components/summary';
import {observer} from 'mobx-react';
import {useContext, useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {NetworkContext} from '../lib/context';
import RevisionToggle from '../components/revision-toggle';
import {ResetAlt} from '@carbon/icons-react';
import Spinner from '../../../components/spinner';

const PAGE_HEIGHT = 'h-[calc(100vh-11.5rem)]';

function TimeDistanceAccessPage() {
    const networkOptimizationStore = useContext(NetworkContext);
    const {rosterId} = useParams();
    const navigate = useNavigate(); 

    useEffect(() => {
        if (!rosterId) {
            navigate('/network-optimization/my-files', {replace: true});
        }
    }, [rosterId, navigate]);

    if (!rosterId) {
        return null;
    }

    const SummaryView = () => (
        <div className="bg-background-gray-100 relative">
            <div className={`time-distance-access-page grid grid-cols-1 grid-rows-1 gap-8 ${PAGE_HEIGHT} p-4 pl-12 mr-[calc(16rem+6rem)]`}>
                <div className="col-span-1 h-full">
                    <ComplianceSummaryTable />
                </div>
            </div>
            <div className='time-distance-access-page absolute right-0 top-0 h-[calc(100%)]'>
                <GeographyFilters />
            </div>
        </div>
    );

    const ResultsView = () => (
        <div className='bg-background-gray-100 relative'>
            <div className={`time-distance-access-page grid grid-cols-5 grid-rows-5 gap-8 ${PAGE_HEIGHT} p-4 pl-12 mr-[calc(16rem+6rem)]`}>
                <div className="row-span-5">
                    <SpecialtyFilterTable fullHeight />
                </div>
                <div className="col-span-4">
                    <ComplianceScore />
                </div>
                <div className="row-span-4 col-span-4">
                    <NetworkMap />
                </div>
            </div>
            <div className='time-distance-access-page absolute right-0 top-0 h-[calc(100%)]'>
                <GeographyFilters />
            </div>

        </div>
    );

    const ExploreView = observer(() => {
        const networkOptimizationStore = useContext(NetworkContext);
        const [loading, setLoading] = useState<boolean>(false);
        function revertToOriginal() {
            setLoading(true);
            networkOptimizationStore.revertProviderRosterToOriginal(new AbortController())
                .then(() => networkOptimizationStore.showRevertModal = false)
                .finally(() => setLoading(false));
        }
        
        return <div className="bg-background-gray-100 relative">
            <div
                className={`time-distance-access-page grid grid-cols-9 grid-rows-5 gap-8 ${PAGE_HEIGHT} p-4 pl-12 mr-[calc(16rem+6rem)]`}>
                <div className="col-span-2 row-span-3">
                    <ComplianceScore compactView />
                </div>
                <div className="col-span-7 row-span-3">
                    <NetworkMap />
                </div>
                <div className="col-span-9 row-span-2">
                    <Layer className="h-full">
                        <AdditionalProvidersTable />
                    </Layer>
                </div>
            </div>
            <div className="time-distance-access-page absolute right-0 top-0 h-[calc(100%)]">
                <GeographyFilters />
            </div>
            <Modal open={networkOptimizationStore.showRevertModal} size="sm" modalHeading="Revert File to Original Version"
                secondaryButtonText="Cancel" primaryButtonText="Revert to Original"
                onRequestClose={() => networkOptimizationStore.showRevertModal = false}
                onSecondarySubmit={() => networkOptimizationStore.showRevertModal = false}
                onRequestSubmit={revertToOriginal}>
                <p>Unsaved changes have been made to this revised version of the current file/roster. Would you like to
                    discard these changes and revert to the original file/roster?</p>
                {loading && <div className="w-full flex justify-end">
                    <Spinner />
                </div>}
            </Modal>
        </div>;
    });

    const tabs = [
        {
            label: 'Summary',
            content: <SummaryView />,
        },
        {
            label: 'Results',
            content: <ResultsView />,
        },
        {
            label: 'Explore Additional Providers',
            content: <ExploreView />,
        },
    ];

    return <div className="time-distance-access pt-5 bg-white">
        <h1 className="m-0 ml-12 mb-1 text-[28px] font-normal font-inherit">Time and Distance Access</h1>
        {networkOptimizationStore.rosterFileName && <div className="relative">
            <div className="m-0 ml-12 mb-1 flex space-x-5 items-center text-[14px]">
                <div className="italic">{networkOptimizationStore.rosterFileName}</div>
                <RevisionToggle />
            </div>
            {networkOptimizationStore.activeTab === 2 && networkOptimizationStore.revisionCount > 0 && <div className="revert-revision">
                <Button kind="tertiary" renderIcon={ResetAlt} onClick={() => networkOptimizationStore.showRevertModal = true}>Revert to Original</Button>
            </div>}
        </div>}
        <Tabs tabs={tabs} onClick={(i) => networkOptimizationStore.activeTab = i}/>
    </div>;
}

export default observer(TimeDistanceAccessPage);



// src/pages/network-optimization/index.tsx
import React, {useContext, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {Outlet, useLocation} from 'react-router-dom';
import {NetworkContext} from './lib/context';
import AuthWall from '../../components/auth-wall';
import {useNavigate} from 'react-router';
import {HeaderContext} from '../../components/header/context';
import Header from '../../components/header';
import MyContent from '../../components/header/my-content/my-content';

const NetworkOptimizationViewPage: React.FC = observer(() => {
    const networkOptimizationStore = useContext(NetworkContext);
    const headerStore = useContext(HeaderContext);
    const navigate = useNavigate();
    const location = useLocation();

    const isNetworkOptimization = location.pathname.includes('/network-optimization');
    headerStore.useNewDesign = true;
    
    const invalidPaths = [
        '/network-optimization/time-distance-access',
        '/network-optimization/null/time-distance-access',
        '/network-optimization/NaN/time-distance-access',
    ];
    
    useEffect(() => {
        if (location.pathname === '/network-optimization' && !networkOptimizationStore.runId) {
            navigate('/network-optimization/my-files');
        }
        
        if (invalidPaths.includes(location.pathname)) {
            navigate('/network-optimization/my-files');
        }

        headerStore.hideExtraButtons = isNetworkOptimization;
    }, [navigate, location.pathname, headerStore, isNetworkOptimization]);

    const headerNavItems = networkOptimizationStore.navItems.map((item) => ({label: item.label, path: `${item.useRosterID ? `${networkOptimizationStore.runId}/` : ''}${item.path}`}));

    return (
        <AuthWall>
            <NetworkContext.Provider value={networkOptimizationStore}>
                <div className="network-optimization-page">
                    <header className="header">
                        <Header siteNameOverride="Network Adequacy" showLogo={false} showOrg navItems={headerNavItems} />
                    </header>
                    <Outlet />
                    {headerStore.showMyContent && <div className="fixed right-0 top-0 h-screen w-1/5 z-[100]">
                        <MyContent />
                    </div>}
                </div>
            </NetworkContext.Provider>
        </AuthWall>
    );
});
export default NetworkOptimizationViewPage;
import {makeAutoObservable, runInAction} from 'mobx';
import {networkOptimization} from '../../../lib/api';
import {
    GeographyFilterOptions,
    FetchGeoJsonResponse,
    FetchComplianceResponse,
    FetchProvidersResponse,
    FetchRunIdResponse,
    AppliedGeographyFilter, DISTANCE_CALC_TYPES,
} from './types';
import Filter, {applyFilter} from '../../../lib/filter';

export default class NetworkOptimizationStore {

    static empty() {
        return new NetworkOptimizationStore();
    }

    // Store properties
    patientGeoJson: FetchGeoJsonResponse | null = null;
    private _providerGeoJson: FetchGeoJsonResponse | null = null;
    
    appliedGeographyFilter: AppliedGeographyFilter = {
        state: '',
        county: ''
    };
    distanceCalcType: string = DISTANCE_CALC_TYPES[0].value;
    availableGeographyFilters: Array<GeographyFilterOptions> = [];
    _specialtyFilter = '';
    specialties: string[] = [];
    loadingRevisionRecalculation = false;
    loadingGeographyData = false;
    disableRecalculateRevision = true;
    complianceScores: FetchComplianceResponse = [];
    private _localProviders: FetchProvidersResponse = [];
    existingProviders: FetchProvidersResponse = [];
    runId: FetchRunIdResponse = null;
    error: string | null = null;
    navItems: {label: string; path: string, useRosterID: boolean}[] = [];
    revisionCount = 0;
    rosterFileName = '';
    selectedAdditionalProviderKeys: string[] = [];
    deselectedExistingProviderKeys: string[] = [];
    existingProviderQueuedForDeletion: string | null = null;
    showRevertModal = false;
    activeTab = 0;
    filters: Filter[] = [];

    constructor() {
        this.loadHeaders();
        makeAutoObservable(this);
    }

    // Load initial data for the store
    async loadGeographyData() {
        try {
            this.loadingGeographyData = true;
            const geographyResponse = await networkOptimization.fetchGeographyFilters(this.runId! as number) as Array<GeographyFilterOptions>;
            runInAction(() => {
                this.availableGeographyFilters = geographyResponse;
            });
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        } finally {
            this.loadingGeographyData = false;
        }
    }
    
    //fetchNavheaders
    async loadHeaders() {
        try {
            const response = await networkOptimization.fetchNavItems();
            runInAction(() => {
                this.navItems = response;
            });
        } catch (error) {
            console.error('Failed to fetch navigation items:', error);
        }
    }

    // Fetch and update the map data
    async loadMapGeoJson(controller: AbortController) {
        if (!this.readyToLoadData())
            return;
        try {
            let filters: Record<string, string> = {
                ...this.geographyFilters
            };
            if (this.specialtyFilter)
                filters.specialty = this.specialtyFilterCode;
            const patientServiceCall: any = this.specialtyFilter ? networkOptimization.fetchPatientGeoJson : networkOptimization.fetchPatientPopulationGeoJson;

            this.patientGeoJson = null;
            
            const [patientData, providerData] = await Promise.all([
                patientServiceCall(filters, this.runId!, !!this.revisionCount, this.distanceCalcType, controller.signal),
                networkOptimization.fetchProviderGeoJson(filters, this.runId!, !!this.revisionCount, controller.signal)
            ]) as [FetchGeoJsonResponse, FetchGeoJsonResponse];

            this.patientGeoJson = patientData;
            this._providerGeoJson = providerData;
        } catch (error) {
            this.error = this.extractErrorMessage(error);
            console.error('Failed to fetch map data:', error);
        }
    }

    // Fetch and update the local providers list
    async loadLocalProviders(controller: AbortController) {
        if (!this.readyToLoadData())
            return;
        try {
            let filters: Record<string, string> = {
                ...this.geographyFilters
            };
            if (this.specialtyFilter)
                filters.specialty = this.specialtyFilterCode;
            let [localProviders, existingProviders]  = await Promise.all([networkOptimization.fetchProviders(filters, this.runId!, !!this.revisionCount, controller.signal),
                networkOptimization.fetchProviderReport(filters, this.runId!, !!this.revisionCount, controller.signal)]) as [FetchProvidersResponse, FetchProvidersResponse];
            this._localProviders = localProviders.map(provider => ({...provider, id: provider.provider_address_fact_key}));
            this.existingProviders = existingProviders.map(provider => ({...provider, id: provider.provider_address_fact_key}));
        } catch (error) {
            this.error = this.extractErrorMessage(error);
            throw error;
        }
    }

    async loadSpecialtiesData(controller: AbortController) {
        if (!this.readyToLoadData())
            return;
        try {
            const filters = {
                ...this.geographyFilters
            };

            const specialtyReport = await networkOptimization.fetchSpecialtyReport(filters, this.runId!, !!this.revisionCount, this.distanceCalcType, controller.signal);
            runInAction(() => {
                this.specialties = Array.from(new Set(specialtyReport.map((item: any) => item.specialty_code)));
                this.complianceScores = specialtyReport.map((item: any) => ({
                    name: item.specialty_name,
                    specialtyCode: item.specialty_code,
                    compliance: item.final_adequacy_flag === '1',
                    scores: [
                        {name: 'Time', value: this.formatPercentage(item.population_met_time), compliant: item.time_adequacy_flag === '1'},
                        {name: 'Distance', value: this.formatPercentage(item.population_met_distance), compliant: item.distance_adequacy_flag === '1'},
                        {name: `# of ${item.specialty_code === '040' ? 'Beds' : 'Provider Locations'}`, value: item.total_adequate_units, compliant: item.min_docs_flag === '1'},
                        {name: 'Total Compliance', value: null, compliant: item.final_adequacy_flag === '1', cssClasses: item.final_adequacy_flag === '1' ? 'bg-green-300 font-bold' : 'bg-red-300 font-bold'},
                        {name: 'Min Required', value: item.minimum_required, compliant: item.min_required_adequacy_flag < item.total_adequate_units}
                    ]
                }));
            });
        } catch (error) {
            this.specialties = [];
            this.complianceScores = [];
            this.error = this.extractErrorMessage(error);
            console.error('Failed to fetch specialties and compliance scores:', error);
        }
    }

    async updateRoster(controller: AbortController) {
        if (!this.runId || !this.specialtyFilterCode)
            return;
        try {
            await networkOptimization.updateProviderRoster(this.runId, this.specialtyFilterCode, this.selectedAdditionalProviderKeys, this.deselectedExistingProviderKeys, controller.signal);
            this.disableRecalculateRevision = false;
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        }
    }

    async recalculateRevision(controller: AbortController) {
        if (!this.runId || !this.specialtyFilterCode)
            return;
        try {
            this.disableRecalculateRevision = true;
            this.loadingRevisionRecalculation = true;
            await networkOptimization.recalculateRevision(this.runId, this.specialtyFilterCode, this.geographyFilters.county, controller.signal);
            this.revisionCount++;
        } catch (error) {
            this.error = this.extractErrorMessage(error);
        } finally {
            this.loadingRevisionRecalculation = false;
        }
    }

    toggleRevision() {
        this.revisionCount = this.revisionCount ? 0 : 1;
    }

    calculateGeneralComplianceScores() {
        if (this.readyToLoadData() && this.complianceScores.length > 0 && (this.providerGeoJson && this.providerGeoJson?.features?.length > 0))
            return {
                scores: [
                    {name: '# of Provider Locations', value: this.providerGeoJson.features.length, compliant: null},
                    {name: 'Total Compliance', value: `${this.complianceScores.filter(cs => cs.compliance).length} / ${this.complianceScores.length}`, compliant: null}
                ]
            };
        return {scores: []};
    }

    // Setters for applied filters
    setGeographyFilter(filterName: 'state' | 'county', value: string) {
        if (filterName === 'state') {
            this.appliedGeographyFilter.state = value;
        } else if (filterName === 'county') {
            this.appliedGeographyFilter.county = value;
        }
        this.appliedGeographyFilter = {...this.appliedGeographyFilter};
        this.specialtyFilter = '';
    }

    async revertProviderRosterToOriginal(controller: AbortController) {
        if (!this.runId || !this.specialtyFilterCode)
            return;
        await networkOptimization.resyncProviderRosterRevision(this.runId, this.specialtyFilterCode, controller.signal);
        this.revisionCount = 0;
    }

    get geographyFilters(): { state: string; county: string; } | Record<string, never> {
        let state = this.availableGeographyFilters.find(o => o.state === this.appliedGeographyFilter.state);
        let county = state?.counties.find(o => `${o.county_name}-${o.county_ssa}` === this.appliedGeographyFilter.county);
        return {state: state?.code || '', county: county?.county_ssa || ''};
    }

    get specialtyFilterCode() {
        return this.complianceScores.find(o => o.name === this.specialtyFilter)?.specialtyCode || null;
    }

    get specialtyFilter() {
        return this._specialtyFilter;
    }

    set specialtyFilter(value: string) {
        this._specialtyFilter = value;
    }

    readyToLoadData() {
        return this.appliedGeographyFilter.state && this.appliedGeographyFilter.county && this.runId;
    }

    private formatPercentage(value: number) {
        return `${(value * 100).toFixed(2)}%`;
    }

    // Extract error message from the error object
    private extractErrorMessage(error: any): string {
        if (error instanceof Error) {
            return error.message;
        }
        return 'An unexpected error occurred';
    }

    selectAdditionalProviders(keys: string[]) {
        this.selectedAdditionalProviderKeys = this.selectedAdditionalProviderKeys.concat(keys);
    }

    deselectAdditionalProviders(keys: string[]) {
        this.selectedAdditionalProviderKeys = this.selectedAdditionalProviderKeys.filter(n => !keys.includes(n));
    }

    deselectAllAdditionalProviders() {
        this.selectedAdditionalProviderKeys = [];
    }

    get selectedAdditionalProviders() {
        return this.localProviders.filter(p => this.selectedAdditionalProviderKeys.some(s => s === p['id']));
    }

    get nonSelectedAdditionalProviders() {
        return this.localProviders.filter(p => !this.selectedAdditionalProviderKeys.some(s => s === p['id']));
    }

    selectExistingProviders(keys: string[]) {
        this.deselectedExistingProviderKeys = this.deselectedExistingProviderKeys.filter(n => !keys.includes(n));
    }

    selectAllExistingProviders() {
        this.deselectedExistingProviderKeys = [];
    }

    deselectExistingProviders(keys: string[]) {
        this.deselectedExistingProviderKeys = this.deselectedExistingProviderKeys.concat(keys);
    }

    get deselectedExistingProviders() {
        return this.existingProviders.filter(p => this.deselectedExistingProviderKeys.some(s => s === p['id']));
    }

    get providerGeoJson(): FetchGeoJsonResponse | null {
        return this._providerGeoJson ? {...this._providerGeoJson, features: this._providerGeoJson.features.filter(f => !this.deselectedExistingProviderKeys.some(p => p === f.properties!.provider_address_fact_key))} : this._providerGeoJson;
    }

    set providerGeoJson(value: FetchGeoJsonResponse | null) {
        this._providerGeoJson = value;
    }

    get localProviders(): FetchProvidersResponse {
        return this.filterProviderList(this._localProviders).concat(this.deselectedExistingProviders);
    }

    filterProviderList(providerList: any[]) {
        let filteredList = providerList;

        if (this) {
            this.filters.forEach(filter => {
                filteredList = applyFilter(filteredList, filter);
            });
        }

        return filteredList;
    }

}


import {DataTable, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, DataTableSkeleton} from '@carbon/react';
import React, {useContext, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {NetworkContext} from '../lib/context';
import ComplianceIndicator from './compliance-indicator';

const ComplianceSummaryTable = observer(() => {
    const networkOptimizationStore = useContext(NetworkContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const COMPLIANCE_TARGET = 90;

    useEffect(() => {
        const controller = new AbortController();
        const loadData = async () => {
            setError(null);
            setLoading(true); 
            try {
                await networkOptimizationStore.loadSpecialtiesData(controller);
            } catch (err) {
                setError('Failed to load compliance summary data. Please try again later.');
            } finally {
                setLoading(false); 
            }
        };

        loadData();
        return () => controller.abort();
    }, [networkOptimizationStore.appliedGeographyFilter, networkOptimizationStore.runId, networkOptimizationStore.revisionCount, networkOptimizationStore.distanceCalcType]);

    const {complianceScores} = networkOptimizationStore;

    if (!networkOptimizationStore.readyToLoadData()) {
        return null;
    }

    if (error) {
        return <p className="text-red-500">{error}</p>;
    }

    const rows = complianceScores.length > 0 ? complianceScores.map((score, index) => ({
        id: index.toString(),
        cells: [
            {id: 'specialty', value: score.name},
            {id: 'passFail', value: <ComplianceIndicator passed={score.compliance} showText />},
            {id: 'minRequired', value: score.scores[4]?.value || '-'},
            {id: 'numProviders', value: score.scores[2]?.value || '-'},
            {id: 'accessTime', value: score.scores[0]?.value || 0},
            {id: 'accessDistance', value: score.scores[1]?.value || 0},
            {id: 'complianceScore', value: `${COMPLIANCE_TARGET}%`},
        ],
    })) : [];

    const headers = [
        {key: 'specialty', header: 'Specialty'},
        {key: 'passFail', header: 'Pass/Fail'},
        {key: 'minRequired', header: 'Min Required'},
        {key: 'numProviders', header: '# of Providers'},
        {key: 'accessTime', header: '% with Access (Time)'},
        {key: 'accessDistance', header: '% with Access (Distance)'},
        {key: 'complianceScore', header: 'Compliance Target'},
    ];

    const getCellClassName = (cellId: string, cellValue: any, passed: boolean, minRequired: number) => {
        if (['accessTime', 'accessDistance'].includes(cellId)) {
            return cellValue < COMPLIANCE_TARGET ? 'bg-yellow-200' : 'bg-green-200';
        }
    
        if (['complianceScore'].includes(cellId)) {
            return !passed ? 'bg-yellow-200' : 'bg-green-200';
        }
        
        if (cellId === 'numProviders') {
            return cellValue > minRequired ? 'bg-green-200' : 'bg-yellow-200';
        }
        return '';
    };
    
    return (
        <div className="compliance-summary-page bg-white p-4 w-full">
            <h3 className="text-xl font-bold mb-4">Compliance Summary</h3>
            {loading ? <DataTableSkeleton 
                headers={headers} 
                columnCount={headers.length}
                rowCount={12}
                showHeader={false}
                showToolbar={false}
            /> : <TableContainer className="max-h-[70vh] overflow-x-auto">
                <DataTable rows={rows} headers={headers}>
                    {({headers}) => (
                        <Table className="min-w-full">
                            <TableHead>
                                <TableRow className="sticky table top-0 bg-white z-10">
                                    {headers.map(header => (
                                        <TableHeader
                                            key={header.key}
                                            className={`bg-white z-20 border-b border-gray-300 text-left p-2 ${
                                                header.key === 'specialty' ? 'w-[40%]' : 'w-[10%]'
                                            }`}
                                        >
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="block max-h-[60vh] overflow-y-auto">
                                {rows.map(row => (
                                    <TableRow key={row.id} className="table w-full">
                                        {row.cells.map((cell, index) => {
                                            const minRequiredCell = row.cells.find(c => c.id === 'minRequired');
                                            const minRequired = minRequiredCell ? parseFloat(minRequiredCell.value) || 0 : 0;
                                            return (
                                                <TableCell
                                                    key={cell.id}
                                                    className={`p-2 ${getCellClassName(cell.id, parseFloat(cell.value), row.cells[1].value.props.passed, minRequired)}`}
                                                    style={{width: index === 0 ? '40%' : '10%'}}
                                                >
                                                    {cell.value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </DataTable>
            </TableContainer>}
        </div>
    );
});

export default ComplianceSummaryTable;

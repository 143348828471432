import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    Checkbox,
    DataTableSkeleton,
    TableToolbar,
    TableBatchActions,
    TableBatchAction,
    Loading,
    InlineNotification,
    Modal,
    DataTableHeader,
    Popover,
    PopoverContent,
    DataTableSortState,
    TableToolbarContent,
    DismissibleTag,
    Button,
} from '@carbon/react';
import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import './additional-providers-table.scss';
import {NetworkContext} from '../lib/context';
import {observer} from 'mobx-react';
import {Filter as FilterIcon, Save} from '@carbon/icons-react';
import getFormatter, {PercentFormatter} from '../../../lib/formatter';
import {AutoSizer, List} from 'react-virtualized';
import {getTextWidth} from '../../../insight-container/charts/common';
import handleError from '../../../lib/error';
import {sortValues} from '../../../lib/sort';
import VariableFilter from '../../../components/variable-filter';
import Variable from '../../../lib/metadata';

const NUMERIC_COLUMNS = ['marginal_impact_time', 'marginal_impact_distance', 'final_mips_score'];

export function UpdateRosterButton({onClick}: {onClick: () => Promise<void>}) {
    let [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const handleClick = async () => {
        setLoading(true);
        setError(null);
        try {
            await onClick();
        } catch (err) {
            setError('Failed to update roster. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <TableBatchAction
                className={loading ? 'gap-2' : ''}
                iconDescription="Save"
                renderIcon={loading ? () => <Loading withOverlay={false} small /> : Save}
                onClick={handleClick}
            >
                Update Roster
            </TableBatchAction>
            {error && <InlineNotification kind="error" title="Error" subtitle={error} />}
        </>
    );
}

const SORT_ORDER: DataTableSortState[] = ['NONE', 'ASC', 'DESC'];

function AdditionalProvidersTable() {
    const networkOptimizationStore = useContext(NetworkContext);
    const [selection, setSelection] = useState<string[]>([]);
    const [deselection, setDeselection] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [scrollToExistingProvider, setScrollToExistingProvider] = useState<string | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState(SORT_ORDER[0]);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [filterHeader, setFilterHeader] = useState<{key: string, header: string, type?: string}>();
    const {localProviders, existingProviders, existingProviderQueuedForDeletion, filters, filterProviderList} = networkOptimizationStore;
    const listRef = useRef<List>(null);

    useEffect(() => {
        setSelection(networkOptimizationStore.selectedAdditionalProviderKeys);
        setDeselection(networkOptimizationStore.deselectedExistingProviderKeys);
    }, [networkOptimizationStore.selectedAdditionalProviderKeys, networkOptimizationStore.deselectedExistingProviderKeys]);

    useEffect(() => setScrollToExistingProvider(existingProviderQueuedForDeletion), [existingProviderQueuedForDeletion]);

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true);
        setError(null);
        const loadData = async () => {
            try {
                await networkOptimizationStore.loadLocalProviders(controller);
                networkOptimizationStore.deselectAllAdditionalProviders();
                networkOptimizationStore.selectAllExistingProviders();
                setLoading(false);
            } catch (err) {
                handleError(err as Error, setError);
            }
        };

        loadData();

        return () => controller.abort();
    }, [networkOptimizationStore.specialtyFilter, networkOptimizationStore.appliedGeographyFilter, networkOptimizationStore.runId, networkOptimizationStore.revisionCount, networkOptimizationStore.distanceCalcType]);
    
    
    const tableHeaders = useMemo(() => {
        let baseHeaders = [
            {key: 'npi', header: 'NPI'},
            {key: 'full_name', header: 'Name'},
            {key: 'first_line_practice_location_address', header: 'Address'},
            {key: 'practice_location_address_city_name', header: 'City'},
            {key: 'practice_location_address_state_name', header: 'State'},
            {key: 'practice_location_address_postal_code', header: 'Zip'},
            {key: 'aligned_group_name', header: 'Provider Group'},
            {key: 'aligned_health_system_name', header: 'Health System Affiliation'},
            {key: 'final_mips_score', header: 'Quality Score', type: 'number'},
            {key: 'marginal_impact_time', header: 'Impact Time', type: 'percent'},
            {key: 'marginal_impact_distance', header: 'Impact Distance', type: 'percent'},
        ];
    
        if (networkOptimizationStore.specialtyFilter === 'Acute Inpatient Hospital') {
            baseHeaders.push({key: 'beds', header: 'Beds', type: 'number'});  
        }
    
        return baseHeaders;
    }, [networkOptimizationStore.specialtyFilter]);

    function sortRows(header: DataTableHeader) {
        return () => {
            setSortColumn(header.key);
            setSortDirection((s) => sortColumn !== header.key ? SORT_ORDER[1] : SORT_ORDER[(SORT_ORDER.findIndex(o => s === o) + 1) % SORT_ORDER.length]);
        };
    }

    function sortProviderList(providerList: any[]) {
        return providerList.slice().filter(p => !!p[sortColumn])
            .sort((a, b) => sortValues(a[sortColumn], b[sortColumn], sortDirection === 'ASC', NUMERIC_COLUMNS.some(c => c === sortColumn)))
            .concat(providerList.filter(p => !p[sortColumn]));
    }

    const tableRows = useMemo(() => {
        const sortedLocalProviders = sortProviderList(localProviders).map(p => ({
            ...p,
            existing: false,
            ...(networkOptimizationStore.specialtyFilter === 'Acute Inpatient Hospital' && {beds: p.beds || 'N/A'})
        }));
    
        const sortedExistingProviders = sortProviderList(filterProviderList.bind(networkOptimizationStore)(existingProviders)).map(p => ({
            ...p,
            existing: true,
            ...(networkOptimizationStore.specialtyFilter === 'Acute Inpatient Hospital' && {beds: p.beds || 'N/A'})
        }));
    
        return sortedLocalProviders.concat(sortedExistingProviders);
    }, [localProviders, existingProviders, sortColumn, sortDirection, networkOptimizationStore.specialtyFilter, filters]);
    
    useEffect(() => {
        if(listRef.current)
            listRef.current.recomputeRowHeights();
    }, [tableRows]);

    if (error)
        return <div className='error'>{error}</div>;

    if (loading)
        return <DataTableSkeleton headers={[{key: ' ', header: ' '}, ...tableHeaders]} rowCount={3} columnCount={tableHeaders.length + 1} compact showToolbar={false} />;

    return <div className='additional-providers-table h-full'>
        {tableRows.length === 0 && <><p>No providers found</p>{filters.length && <Button onClick={() => networkOptimizationStore.filters = []}>Reset Filters</Button>}</>}
        {!!tableRows.length && <DataTable rows={tableRows} headers={tableHeaders} size='sm' isSortable stickyHeader>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getTableContainerProps,
                getToolbarProps,
                getBatchActionProps,
            }) => <TableContainer title={<div className='font-semibold mt-[-1.25rem] mb-[-.75rem]'>Available Providers/Facilities{networkOptimizationStore.specialtyFilter && <> - {networkOptimizationStore.specialtyFilter}</>}</div>} {...getTableContainerProps()}>
                <TableToolbar {...getToolbarProps()} size='sm' aria-label="data table toolbar">
                    <TableToolbarContent>
                        {/* <Button kind='ghost' onClick={() => {}}>Primary Button</Button> */}
                        {filters && filters.map((filter, i) => 
                            <DismissibleTag key={i} onClose={() => networkOptimizationStore.filters = filters.filter(f => f !== filter)} text={`${filter.variable.label} ${filter.test} ${filter.value}`} />
                        )}
                    </TableToolbarContent>
                    <TableBatchActions {...getBatchActionProps()} shouldShowBatchActions={!!selection.length || !!deselection.length} translateWithId={
                        (id) => {
                            if (id === 'carbon.table.batch.cancel') return 'Cancel';
                            return `${selection.length ? `${selection.length} provider${selection.length > 1 ? 's' : ''} to add${deselection.length ? ', ' : ''}` : ''}${deselection.length ? `${deselection.length} provider${deselection.length > 1 ? 's' : ''} to remove` : ''}`;
                        }
                    } totalSelected={selection.length + deselection.length} onCancel={() => {networkOptimizationStore.deselectAllAdditionalProviders();networkOptimizationStore.selectAllExistingProviders();}}>
                        <UpdateRosterButton
                            onClick={async () => {
                                try {
                                    await networkOptimizationStore.updateRoster(new AbortController());
                                } catch (err) {
                                    setError('Failed to update roster. Please try again later.');
                                }
                            }}
                        />
                    </TableBatchActions>
                </TableToolbar>
                <Table {...getTableProps()} aria-label="sample table" className='!overflow-y-hidden' >
                    <TableHead className='pr-4'>
                        <TableRow>
                            <TableHeader className='select-col' key='select' >
                                &nbsp;
                            </TableHeader>
                            {headers.map((header, i) => {
                                let headerProps = getHeaderProps({header});
                                let headerOnClick = sortRows(header);
                                return <TableHeaderView header={header} key={i} headerProps={{...headerProps, key: header.key, isSortHeader: header.key === sortColumn, sortDirection, onClick: headerOnClick}} onFilter={(h) => {setFilterHeader(tableHeaders.find(header => h === header.key));}} />;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody className='h-[calc(100%-2rem)] !overflow-y-hidden'>
                        <div className='h-full'>
                            <AutoSizer>
                                {({height, width}) => (
                                    <List ref={listRef} height={height} rowHeight={({index}) => {
                                        const cellWidth = ((width - 52) / headers.length) - 32; //cell x padding
                                        const maxWidth = Math.max(...rows[index].cells.map(c => getTextWidth(c.value ? c.value.toString() : '' + '      ', 18)));
                                        const lines = Math.ceil(maxWidth / cellWidth);
                                        return (lines * 18) + 6 + 7 + 1; //line height and cell y padding
                                    }} rowCount={rows.length} width={width} overscanRowCount={10} rowRenderer={({index: i, style, key}) => {
                                        const row = rows[i];
                                        const rowProps = getRowProps({row});
                                        const existingProvider = existingProviders.some(p => p.id === row.id);
                                        const checked = !existingProvider ? selection.includes(row.id) : !deselection.includes(row.id);
                                        return (
                                            <>
                                                <TableRow {...rowProps} key={key} style={style} className={checked ? 'checked-row' : ''}>
                                                    <TableCell className='select-col' key={`select-${row.id}`}>
                                                        <Checkbox id={`check-${row.id}`} labelText='' checked={checked} onChange={(_, {checked}) => {
                                                            const isExistingProvider = existingProviders.some(p => p.id === row.id);
                                                            const isLastExistingProvider = isExistingProvider && deselection.length === existingProviders.length - 1;

                                                            if (!checked && isLastExistingProvider) {
                                                                setModalMessage('You must have at least one existing provider selected.');
                                                                setModalOpen(true);
                                                                return;
                                                            }

                                                            let operation = checked ? networkOptimizationStore.selectAdditionalProviders : networkOptimizationStore.deselectAdditionalProviders;
                                                            if (existingProvider) 
                                                                operation = checked ? networkOptimizationStore.selectExistingProviders : networkOptimizationStore.deselectExistingProviders;
                                                            operation.call(networkOptimizationStore, [row.id]);
                                                        }} />
                                                    </TableCell>
                                                    {row.cells.map(cell => <TableCell key={cell.id}>{cell.value && ['marginal_impact_time', 'marginal_impact_distance'].some(h => cell.id.split(':')[1] === h) ? new PercentFormatter(.01, null).formatValue(cell.value) : cell.value}</TableCell>)}
                                                </TableRow>
                                            </>
                                        );
                                    }}
                                    scrollToIndex={rows.findIndex(r => r.id === (scrollToExistingProvider ? scrollToExistingProvider : selection[selection.length - 1]))}
                                    scrollToAlignment="start"
                                    />)
                                }
                            </AutoSizer>
                        </div>
                    </TableBody>
                </Table>
            </TableContainer>}
        </DataTable>}
        <Modal
            open={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            primaryButtonText="Close"
            onRequestSubmit={() => setModalOpen(false)}  
        >
            <h1>{modalMessage}</h1>
        </Modal>
        {filterHeader && <VariableFilter data={tableRows} config={{variable: new Variable(filterHeader.key, filterHeader.header, undefined, filterHeader.type || 'string', {'Format': filterHeader.type || 'string'}, getFormatter(filterHeader.type || 'string')), filter: null}} onApply={(f) => {setFilterHeader(undefined);networkOptimizationStore.filters = [...filters, f];}} onCancel={() => {setFilterHeader(undefined);}} />}
    </div>;
}

function TableHeaderView({header, headerProps, onFilter}: {
    header: DataTableHeader,
    headerProps: {
        [key: string]: unknown;
        isSortable: boolean | undefined;
        isSortHeader: boolean;
        key: string;
        onClick: (e: MouseEvent) => void;
        sortDirection: DataTableSortState;
    },
    onFilter: ((key: string) => void) | undefined;
}) {
    let [popoverOpen, setPopoverOpen] = useState(false);
    let headerOnClick = headerProps.onClick as any;

    return <Popover open={popoverOpen} key={header.key} isTabTip className={`!z-50 w-full border-separate`} onMouseEnter={() => setPopoverOpen(true)} onMouseLeave={() => setPopoverOpen(false)} >
        <TableHeader {...headerProps} onClick={headerOnClick} key={header.key} className={`px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis text-sm w-full !flex`}>
            {header.header}
        </TableHeader>
        <PopoverContent>
            <div className='text-background-gray-700 divide-y bg-white'>
                <div className='hover:bg-background-gray-200 px-4 py-2 hover:cursor-pointer flex hover:text-background-gray-800' onClick={() => onFilter && onFilter(header.key)} >
                    <FilterIcon />
                    <div className='ml-1'>Filter</div>
                </div>
            </div>
        </PopoverContent>
    </Popover>;
}

export default observer(AdditionalProvidersTable);
import {loginRequest, silentRequest} from './entra-auth-config';
import {AccountInfo, IPublicClientApplication, SilentRequest} from '@azure/msal-browser';
import {msalInstance} from './entra-auth-provider';
import auth from '../auth';
/**
 * useMsal is hook that returns the PublicClientApplication instance,
 * that tells you what msal is currently doing. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
 */

const handleLoginRedirect = (instance: IPublicClientApplication) => {
    instance
        .loginRedirect({
            ...loginRequest,
            prompt: 'create',
        })
        .catch((error) => console.log(error));
};

const handleLoginSilent = (instance: IPublicClientApplication) => {
    instance.acquireTokenSilent({
        ...silentRequest,
        account: instance.getAllAccounts()[0]
    }).catch((error) => console.log(error));
};

const handleLogoutRedirect = (instance: IPublicClientApplication) => {
    instance
        .logoutRedirect({
            postLogoutRedirectUri: '/',
        })
        .catch((error) => console.log(error));
    window.location.reload();
};

const getAccessToken = async (account: AccountInfo | null): Promise<string | null> => {
    if (!account || auth.isExpired()) {
        handleLoginRedirect(msalInstance);
        return null;
    }

    const scopes = silentRequest.scopes;
    const tokenRequest: SilentRequest = {
        scopes,
        account
    };

    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    } catch (error) {
        console.error('Token acquisition failed:', error);
        handleLoginRedirect(msalInstance);
        return null;
    }
};

export {
    handleLoginRedirect,
    handleLoginSilent,
    handleLogoutRedirect,
    getAccessToken,
};
import handleError from './error';
import {organizations, users} from './api';
import {Module} from './data-types';
import quantumAccelerator from './quantum-accelerator';
import activityTracker from './activity-tracker';
import {identifyVisitor} from './analytics-provider';
import {IPublicClientApplication} from '@azure/msal-browser';
import {handleLoginRedirect, handleLogoutRedirect} from './entra-auth/entra-auth';
import {msalInstance} from './entra-auth/entra-auth-provider';

const protocol = String(process.env.REACT_APP_API_BASE).startsWith('local.torch') ? 'http://' : 'https://';

let token = sessionStorage.getItem('jwt') || localStorage.getItem('jwt');
if (!token && URLSearchParams) {
    // Attempt to get it from the query string
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');
}

type AuthOrgType = {
    id: number,
    name: string,
};

type PrincipalType = {
    sub: number,
    name: string,
    email: string,
    exp: number,
    accessRoles: Array<string>,
    orgs: Array<AuthOrgType>,
    throttleLimit?: number,
    modules?: Array<Module>
    landing?: string
};

function getPrincipal(): PrincipalType | null {
    if (!token)
        return null;
    try {
        const p = JSON.parse(atob(token.split('.')[1]));
        if (p.exp && p.exp < new Date().getTime() / 1000)
            return null;
        return p;
    } catch (e) {
        console.error(e);
        return null;
    }
}

class InvalidAuthError extends Error {}

function hasRole(role: string): boolean {
    return !!auth.principal && (
        auth.principal.accessRoles.includes(role) ||
        auth.principal.accessRoles.includes('admin')
    );
}

function hasModule(moduleName: string): boolean {
    return !!auth.principal && !!auth.principal.modules && (auth.principal.modules.some(m => m.name === moduleName));
}

function setToken(newToken: string) {
    sessionStorage.setItem('jwt', newToken);
    localStorage.setItem('jwt', newToken);
    token = newToken;
    auth.token = newToken;
    auth.principal = getPrincipal();
}

function setPrevProxyToken(token: string) {
    sessionStorage.setItem('prev-jwt', token);
    localStorage.setItem('prev-jwt', token);
}

function login(email: string, password: string) {
    quantumAccelerator.clearObjectStore();
    return fetch(protocol + process.env.REACT_APP_API_BASE + '/v2/user/authenticate', {
        method: 'POST',
        body: JSON.stringify({email, password}),
    })
        .then(response => {
            if (response.status === 400) {
                handleLoginRedirect(msalInstance);
            } else if (response.status === 401) {
                throw new InvalidAuthError();
            } else if (response.status > 400)
                throw new Error('Error logging in');
            return response.text();
        })
        .then(newToken => setPostLoginTasks(newToken));
}

function loginWithEntraIDToken(idToken: string) {
    quantumAccelerator.clearObjectStore();
    return fetch(protocol + process.env.REACT_APP_API_BASE + '/v2/user/authenticate/entra-id', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        }
    })
        .then(response => {
            if (response.status === 401)
                throw new InvalidAuthError();
            else if (response.status >= 400)
                throw new Error('Error logging in');
            return response.text();
        })
        .then(newToken => setPostLoginTasks(newToken));
}

function setPostLoginTasks(newToken: string) {
    setToken(newToken);
    identifyVisitor();
    return newToken;
}

function refresh() {
    fetch(protocol + process.env.REACT_APP_API_BASE + '/v2/user/authenticate/refresh', {
        method: 'POST',
        headers: {Authorization: 'Bearer ' + auth.token}
    })
        .then(response => response.text())
        .then(newToken => setToken(newToken))
        .catch(err => handleError(err, null));
}

async function proxy(id: number): Promise<string> {
    quantumAccelerator.clearObjectStore();
    const prevToken = auth.token;
    const newToken = await users.proxy.proxy(id);
    setToken(newToken);
    setPrevProxyToken(prevToken!);
    return newToken;
}

async function proxyOrg(id: number): Promise<string> {
    quantumAccelerator.clearObjectStore();
    const prevToken = auth.token;
    const token = await organizations.proxy(id);
    setToken(token);
    setPrevProxyToken(prevToken!);
    return token;
}

function isProxied(): boolean {
    return !!sessionStorage.getItem('prev-jwt') || !!localStorage.getItem('prev-jwt');
}

function isExpired(): boolean {
    return !getPrincipal();
}

function unProxy() {
    if (isProxied()) {
        setToken(sessionStorage.getItem('prev-jwt') || localStorage.getItem('prev-jwt')!);
        localStorage.removeItem('prev-jwt');
        sessionStorage.removeItem('prev-jwt');
        window.location.reload();
    }
}

function logOut(entraInstance?: IPublicClientApplication) {
    quantumAccelerator.clearObjectStore();
    localStorage.removeItem('jwt');
    activityTracker.deleteRecentActivity();
    sessionStorage.removeItem('jwt');
    window.location.assign('//' + process.env.REACT_APP_TI_DOMAIN + '/logout');
    if (entraInstance)
        handleLogoutRedirect(entraInstance);
}

const auth = {
    principal: getPrincipal(),
    token,
    setToken,
    hasRole,
    hasModule,
    login,
    loginWithEntraIDToken,
    refresh,
    proxy,
    proxyOrg,
    InvalidAuthError,
    isProxied,
    isExpired,
    unProxy,
    logOut
};

export default auth;
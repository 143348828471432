import {useContext} from 'react';
import {Edit} from '@carbon/icons-react';
import {observer} from 'mobx-react';
import {NetworkContext} from '../lib/context';


const RevisionToggle = function() {
    const networkOptimizationStore = useContext(NetworkContext);

    return <div className={`${networkOptimizationStore.revisionCount ? 'bg-medinsight-vibrant-blue-300' : 'bg-medinsight-spring-300'} rounded-3xl px-3 py-1 hover:cursor-pointer`} onClick={() => networkOptimizationStore.toggleRevision()}>
        <div className="flex space-x-2 items-center">
            <span>{networkOptimizationStore.revisionCount ? 'Revised' : 'Original'}</span>
            <Edit />
        </div>
    </div>;
};

export default observer(RevisionToggle);
import React, {useContext, useState} from 'react';
import Template from '../../components/template';
import Button from '../../components/button';
import PageMenu from '../../components/page-menu';
import Loading from '../../components/loading';
import {users} from '../../lib/api';
import handleError from '../../lib/error';
import {HeaderContext} from '../../components/header/context';
import LoginTitle from './login-title';
import Input from '../../components/input';
import {Link} from 'react-router-dom';
import Alert from '../../components/alert';
import {CheckmarkFilled, ErrorFilled} from '@carbon/icons-react';
import {handleLoginRedirect} from '../../lib/entra-auth/entra-auth';
import {msalInstance} from '../../lib/entra-auth/entra-auth-provider';


export default function PasswordResetPage() {
    const [sending, setSending] = useState<boolean>(false);
    const [resetSuccessful, setResetSuccessful] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [validation, setValidation] = useState<boolean>(false);
    const headerStore = useContext(HeaderContext);

    function handleErrorMessage(error: Error) {
        if (error.message === 'Entra Id enabled account') {
            handleLoginRedirect(msalInstance);
        } else {
            handleError(error, setErrorMessage);
        }
    }

    function resetPassword() {
        if (!email || !email.includes('@')) {
            setValidation(true);
            return;
        }

        setSending(true);
        users.passwordReset.reset(email)
            .then(() => setResetSuccessful(true))
            .catch(e => handleErrorMessage(e))
            .finally(() => setSending(false));
    }

    function handleKeyUp(event: any) {
        if (event.keyCode === 13)
            resetPassword();
    }

    function legacyPasswordReset() {
        return <div>
            <PageMenu>
                <div className="center">
                    <h1>Reset Your Password</h1>
                </div>
            </PageMenu>
            <div className="container flex flex-col items-center mt-16">
                <div className="flex flex-col space-y-5">
                    <p>Forgotten your password? Enter your email address below, and we'll email instructions for setting a new one.</p>
                    <label className="font-bold">Email:</label>
                    <div className="flex space-x-3">
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} onKeyUp={handleKeyUp}/>
                        <Button disabled={!email} color="blue" onClick={resetPassword}>Reset</Button>
                    </div>
                    {sending && <div className="flex space-x-8 items-center"><span>Sending request...</span><Loading /></div>}
                    {resetSuccessful && <div className="text-green-500">The password request was made. If there is an account with that email then we&apos;ll send an email to it immediately.</div>}
                    {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                </div>
            </div>
        </div>;
    }

    function dppPasswordReset() {
        return <div className="login-page">
            <div className="wrapper">
                <div className='w-full'>
                    <LoginTitle>
                        <span>Reset Password</span>
                    </LoginTitle>
                    {!resetSuccessful && <div>
                        <p>Please enter the email address for your account. You will receive a link to create a new
                            password
                            via email.</p>
                        {errorMessage && <Alert color='red' icon={<ErrorFilled />}>{errorMessage}</Alert>}
                        <div className='mt-8'>
                            <div className='mt-4 text-gray-500 text-sm font-bold'>Email</div>
                            <Input value={email} onChange={e => setEmail(e.target.value)} tabIndex={1}
                                placeholder='Email' validation={validation && (!email || !email.includes('@'))} />
                            <Button color='milliman-blue' className='block w-full mt-8' disabled={sending}
                                loading={sending}
                                onClick={resetPassword}>
                                <div className='w-full h-full flex space-x-3 justify-center items-center py-2'
                                    tabIndex={2}>
                                    <span className='block'>Send password reset link</span>
                                </div>
                            </Button>
                        </div>
                    </div>}
                    {resetSuccessful && <div>
                        <Alert color='green' icon={<CheckmarkFilled />} noDismiss>
                            <div>
                                <strong className='block'>Check your email</strong>
                                <p>If there is an account associated with the email address you entered, you will
                                    receive an email with a link to reset your password.</p>
                            </div>
                        </Alert>
                    </div>}
                    <div className='mt-16 flex justify-center'>
                        <Link className='text-medinsight-blue-300' to='/login'>Back to login</Link>
                    </div>
                </div>
            </div>
        </div>;
    }

    return <Template showFooter={headerStore.useNewDesign}>
        {headerStore.useNewDesign ? dppPasswordReset() : legacyPasswordReset()}
    </Template>;
}
import React, {ComponentType, useContext, useMemo, useRef, useState} from 'react';
import Variable from '../../lib/metadata';
import './table.scss';
import {dataRow, dataRows} from '../../lib/data-types';
import HeaderMenu from './header-menu';
import Cell from './cell';
import HeaderName from './header-name';
import SortIcon from './sort-icon';
import {HeaderContext} from '../header/context';
import {
    DataTable,
    Table as CarbonTable,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Popover,
    PopoverContent,
    DataTableHeader,
    DataTableSortState,
} from '@carbon/react';
import {Filter, Information, TrashCan} from '@carbon/icons-react';

export const MAX_STICKY_HEADERS = 5;
const STICKY_HEADER_WIDTH = 230;

export default function Table(props: {
    headers: Array<Variable>,
    data: dataRows,
    children?: ComponentType<{row: dataRow}>,
    onFilter?: (variable: Variable) => void,
    onInfo?: (variable: Variable) => void,
    onSort?: (variable: Variable) => void,
    onRemove?: (variable: Variable) => void,
    sorts?: Array<[Variable, boolean]>,
    getColorIndex?: (variable: Variable) => number,
    onRowClick?: (row: dataRow) => void,
    hiddenColumns?: Array<Variable>,
    stickyColumns?: Array<Variable>,
    tableBottomMessage?: string
}) {
    let {data, headers, sorts, onSort, onFilter, onInfo, onRemove, getColorIndex, onRowClick, hiddenColumns, stickyColumns, tableBottomMessage} = props;
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const enableSticky: boolean = useMemo(() => (!showFilter && !!stickyColumns && stickyColumns.length > 0), [showFilter, stickyColumns]);
    const {useNewDesign} = useContext(HeaderContext);

    // Sorting
    if (!sorts)
        sorts = [];

    const filteredHeaders = useMemo(() => {
        return headers.filter(h => !hiddenColumns || !hiddenColumns.some(hidden => h.getId() === hidden.getId()));
    }, [headers, hiddenColumns]);

    const stickyHeaders = useMemo(() => {
        let stickyHeaders = filteredHeaders.filter(v => isStickyVariable(v));
        if (stickyHeaders.length > MAX_STICKY_HEADERS)
            stickyHeaders = stickyHeaders.slice(0, MAX_STICKY_HEADERS);
        return stickyHeaders;
    }, [filteredHeaders]);

    const displayHeaders = useMemo(() => {
        return [
            ...stickyHeaders,
            ...filteredHeaders.filter(v => !stickyHeaders.some(sv => sv.name === v.name))
        ];
    }, [stickyHeaders, filteredHeaders, ]);

    const colorIndices = useMemo(() => {
        return displayHeaders.map(h => getColorIndex ? getColorIndex(h) : -1);
    }, [displayHeaders, getColorIndex]);

    const onStickyMouseOver = (i: number, header: Variable) => {
        if (i < MAX_STICKY_HEADERS && isStickyVariable(header)) {
            if (tableRef && tableRef.current)
                tableRef.current.scrollLeft = 0;
            setShowFilter(true);
        }
    };

    function isStickyVariable(v: Variable) {
        return stickyColumns?.some(sc => v.name === sc.name);
    }

    const TableView = useNewDesign ? NewTableView : DefaultTableView;
    const viewProps: TableViewProps = {
        tableRef,
        stickyHeaders,
        displayHeaders,
        isStickyVariable,
        onSort,
        onStickyMouseOver,
        setShowFilter,
        sorts,
        onFilter,
        onInfo,
        onRemove,
        data,
        onRowClick,
        colorIndices,
        enableSticky,
        tableBottomMessage
    };
    return <TableView {...viewProps} />;
}

type TableViewProps = {
    tableRef: React.MutableRefObject<HTMLDivElement | null>;
    stickyHeaders: Variable[];
    displayHeaders: Variable[];
    isStickyVariable: (v: Variable) => boolean | undefined;
    onSort: ((variable: Variable) => void) | undefined;
    onStickyMouseOver: (i: number, header: Variable) => void;
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
    sorts: [Variable, boolean][];
    onFilter: ((variable: Variable) => void) | undefined;
    onInfo: ((variable: Variable) => void) | undefined;
    onRemove: ((variable: Variable) => void) | undefined;
    data: dataRows;
    onRowClick: ((row: dataRow) => void) | undefined;
    colorIndices: number[];
    enableSticky: boolean;
    tableBottomMessage: string | undefined;
};

function DefaultTableView(props: TableViewProps) {
    const {
        tableRef,
        stickyHeaders,
        displayHeaders,
        isStickyVariable,
        onSort,
        onStickyMouseOver,
        setShowFilter,
        sorts,
        onFilter,
        onInfo,
        onRemove,
        data,
        onRowClick,
        colorIndices,
        enableSticky,
        tableBottomMessage
    } = props;
    return <div className="insight-table" ref={tableRef}>
        <table>
            <thead>
                <tr className={stickyHeaders.length === 0 ? 'border-b border-gray-300' : ''}>
                    {displayHeaders.map((h, i) => <th data-testid="sort-button"
                        key={i}
                        className={`${i < MAX_STICKY_HEADERS && isStickyVariable(h) ? 'sticky-col' : ''} bg-gray-200`}
                        style={{left: `${i < MAX_STICKY_HEADERS && isStickyVariable(h) ? STICKY_HEADER_WIDTH * i + 'px' : 'auto'}`}}
                        onClick={() => onSort && onSort(h)}
                        onMouseOver={() => onStickyMouseOver(i, h)}
                        onMouseLeave={() => setShowFilter(false)}>
                        <HeaderName value={h.label} />
                        <SortIcon variable={h} sorts={sorts!} />
                        <HeaderMenu variable={h} onFilter={onFilter} onInfo={onInfo} onRemove={onRemove} />
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {data.map((row, i) => <tr data-testid="data-row"
                    key={i}
                    className={`${stickyHeaders.length > 0 ? 'sticky-col' : ''} ${i % 2 === 0 ? 'dark' : ''}`}
                    onClick={() => onRowClick && onRowClick(row)}>
                    {displayHeaders.map((h, i) => <Cell
                        key={i}
                        variable={h}
                        row={row}
                        colorIndex={colorIndices[i]}
                        left={`${i < MAX_STICKY_HEADERS && isStickyVariable(h) ? STICKY_HEADER_WIDTH * i + 'px' : 'auto'}`}
                        sticky={i < MAX_STICKY_HEADERS && isStickyVariable(h)}
                        enableSticky={enableSticky}
                        lastSticky={i === stickyHeaders.length - 1}
                        allowPopup={typeof onRowClick !== 'function'} />)}
                </tr>)}
            </tbody>
        </table>
        {tableBottomMessage && <div className="w-full mt-5 p-3 bg-white text-center">{tableBottomMessage}</div>}
    </div>;
}

function NewTableView(props: TableViewProps) {
    const {
        tableRef,
        displayHeaders,
        onSort,
        sorts,
        onFilter,
        onInfo,
        onRemove,
        data,
        tableBottomMessage,
        isStickyVariable,
        stickyHeaders,
        onRowClick
    } = props;
    return <div ref={tableRef}>
        <DataTable rows={data.map((r, i) => ({id: i.toString(), ...r}))} headers={displayHeaders.map(h => ({key: h.name, header: h.label}))} experimentalAutoAlign isSortable>
            {({rows, headers, getHeaderProps, getRowProps, getTableProps}) => (
                <CarbonTable {...getTableProps()} className='relative !border-separate' size='sm' >
                    <TableHead>
                        <TableRow>
                            {headers.map((header, i) => {
                                let headerVariable = displayHeaders.find(h => h.name === header.key)!;
                                let headerProps = getHeaderProps({header}) as any;
                                let onClick: (e: MouseEvent) => void = () => {
                                    onSort && onSort(headerVariable);
                                };
                                
                                return <TableHeaderView 
                                    header={header} 
                                    headerProps={{...headerProps, onClick}}
                                    sorts={sorts} 
                                    key={header.key} 
                                    onFilter={onFilter} 
                                    onInfo={onInfo} 
                                    onRemove={onRemove} 
                                    variable={headerVariable} 
                                    isSticky={i < MAX_STICKY_HEADERS && (isStickyVariable(headerVariable) || false)} 
                                    style={{left: `${i < MAX_STICKY_HEADERS && isStickyVariable(headerVariable) ? STICKY_HEADER_WIDTH * i + 'px' : 'auto'}`, position: 'sticky'}} />;
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <TableRow {...getRowProps({row})} onClick={() => onRowClick && onRowClick(data[i])} key={i}>
                                {displayHeaders.map((h, j) => {
                                    return <TableCell 
                                        key={j} 
                                        className={`px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis text-sm ${j < MAX_STICKY_HEADERS && (isStickyVariable(h) || false) ? 'sticky-col' : ''} ${j === stickyHeaders.length - 1 ? 'last-sticky' : '' }`}
                                        style={{left: `${j < MAX_STICKY_HEADERS && isStickyVariable(h) ? STICKY_HEADER_WIDTH * j + 'px' : 'auto'}`, position: 'sticky'}}
                                    >
                                        {h.format(data[i])}
                                    </TableCell>;
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </CarbonTable>
            )}
        </DataTable>
        {tableBottomMessage && <div className="w-full mt-5 p-3 bg-white text-center">{tableBottomMessage}</div>}
    </div>;
}

function TableHeaderView({header, headerProps, sorts, onFilter, onInfo, onRemove, variable, isSticky, style}: {
    header: DataTableHeader,
    headerProps: {
        [key: string]: unknown;
        isSortable: boolean | undefined;
        isSortHeader: boolean;
        key: string;
        onClick: (e: MouseEvent) => void;
        sortDirection: DataTableSortState;
    },
    sorts: [Variable, boolean][],
    onFilter: ((variable: Variable) => void) | undefined;
    onInfo: ((variable: Variable) => void) | undefined;
    onRemove: ((variable: Variable) => void) | undefined;
    variable: Variable;
    isSticky: boolean;
    style?: React.CSSProperties
}) {
    let [popoverOpen, setPopoverOpen] = useState(false);
    let headerOnClick = headerProps.onClick as any;
    let sort = sorts.find(([sortVariable]) => sortVariable.name === header.key);
    let sortDirection = sort ? (sort[1] ? 'ASC' : 'DESC') : 'NONE';

    return <Popover open={popoverOpen} key={header.key} style={style} isTabTip className={`${isSticky ? 'sticky-col' : '!z-50'} !table-cell w-full border-separate`} onMouseEnter={() => setPopoverOpen(true)} onMouseLeave={() => setPopoverOpen(false)} >
        <TableHeader {...headerProps} style={style} onClick={headerOnClick} isSortHeader={!!sort} sortDirection={sortDirection} key={header.key} className={`${isSticky ? 'sticky-col' : ''} px-3 py-2 whitespace-nowrap overflow-hidden overflow-ellipsis text-sm w-full !flex`}>
            {header.header}
        </TableHeader>
        <PopoverContent>
            <div className='text-background-gray-700 divide-y bg-white'>
                <div className='hover:bg-background-gray-200 px-4 py-2 hover:cursor-pointer flex hover:text-background-gray-800' onClick={() => onFilter && onFilter(variable)} >
                    <Filter />
                    <div className='ml-1'>Filter</div>
                </div>
                <div className='hover:bg-background-gray-200 px-4 py-2 hover:cursor-pointer flex hover:text-background-gray-800' onClick={() => onInfo && onInfo(variable)} >
                    <Information />
                    <div className='ml-1'>Information</div>
                </div>
                <div className='hover:bg-background-gray-200 px-4 py-2 hover:cursor-pointer flex hover:text-background-gray-800' onClick={() => onRemove && onRemove(variable)} >
                    <TrashCan />
                    <div className='ml-1'>Remove Column</div>
                </div>
            </div>
        </PopoverContent>
    </Popover>;
}

import React, {useEffect, useContext, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {NetworkContext} from '../lib/context';
import './specialty-filter-table.scss';
import {FilterRemove, Filter, RadioButton, RadioButtonChecked} from '@carbon/icons-react';
import ComplianceIndicator from './compliance-indicator';
import Spinner from '../../../components/spinner';

type Props = {
    fullHeight?: boolean
}

function SpecialtyFilterTable(props: Props) {
    const {fullHeight} = props;
    const networkOptimizationStore = useContext(NetworkContext);
    const parentRef = useRef<HTMLDivElement>(null);
    const [applySpecialtyFilter, setApplySpecialtyFilter] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null);
    const parentOffsetHeight = useMemo(() => Math.max(Number(parentRef.current?.offsetHeight), 340), [parentRef.current, networkOptimizationStore.specialtyFilter]);
    const isSpecialtySelected = networkOptimizationStore.specialtyFilter !== '';

    useEffect(() => {
        const controller = new AbortController();
        const loadData = async () => {
            setLoading(true);
            setError(null);
            try {
                await networkOptimizationStore.loadSpecialtiesData(controller);
            } catch (err) {
                setError('Failed to load specialties data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        loadData();
        return () => controller.abort();
    }, [networkOptimizationStore.appliedGeographyFilter, networkOptimizationStore.runId, networkOptimizationStore.revisionCount, networkOptimizationStore.distanceCalcType]);

    const {complianceScores} = networkOptimizationStore;
    const selectedScore = useMemo(() => complianceScores?.find((score: {name: string }) => score.name === networkOptimizationStore.specialtyFilter), [networkOptimizationStore.complianceScores, networkOptimizationStore.specialtyFilter]);

    const handleSpecialtyClick = () => {
        networkOptimizationStore.specialtyFilter = applySpecialtyFilter;
    };

    const handleClearFilter = () => {
        setApplySpecialtyFilter('');
        networkOptimizationStore.specialtyFilter = '';
        networkOptimizationStore.patientGeoJson = null;
        networkOptimizationStore.providerGeoJson = null;
        networkOptimizationStore.specialtyFilter = '';
    };

    useEffect(() => {
        setApplySpecialtyFilter(networkOptimizationStore.specialtyFilter); 
    }, [networkOptimizationStore.specialtyFilter]);

    if (!networkOptimizationStore.readyToLoadData())
        return null;

    if (loading)
        return <div>
            <Spinner /> Loading specialties...
        </div>;

    return (
        <div className={`specialty-filter bg-white overflow-hidden ${fullHeight ? 'h-full' : 'max-h-96'}`} ref={parentRef}>
            <h1 className="text-lg font-semibold mb-4 ml-4">Access Score by Specialty</h1>

            <div className="flex-grow">
                {loading && <p className="px-6">Loading specialties...</p>}
                {error && <p className="px-6 text-red-500">{error}</p>} 
                
                {!loading && !error && !!complianceScores.length && (
                    <div>
                        {isSpecialtySelected ? (
                            <div className="selected-specialty">
                                <div className="w-full flex justify-end items-center">
                                    <button onClick={handleClearFilter}>
                                        <span className="mr-2">Clear Filter</span>
                                        <FilterRemove/>
                                    </button>
                                </div>
                                {selectedScore && <div className='bg-gray-300 py-2 px-6 flex justify-between items-center'>
                                    <div className='min-w-8'><RadioButtonChecked /></div>
                                    <div className='w-2/3 flex justify-between items-center break-words'>{selectedScore.name}</div>
                                    <div className='w-1/3 flex items-center justify-end'>
                                        <ComplianceIndicator passed={selectedScore.compliance} />
                                    </div>
                                </div>}
                            </div>
                        ) : (
                            <div className='overflow-hidden'>
                                <div className='w-full flex justify-end items-center border-b border-gray-300'>
                                    <button onClick={handleSpecialtyClick} disabled={!applySpecialtyFilter} className={`primary ${applySpecialtyFilter ? '' : 'disabled'}`}>
                                        <span className='mr-2'>Apply as Filter</span>
                                        <Filter />
                                    </button>
                                </div>
                                {parentRef.current && <ul className="w-full overflow-auto" style={{height: `${parentOffsetHeight - 140}px`}}>
                                    {complianceScores.length > 0 ? (
                                        complianceScores.map((specialty: any, i: number) => (
                                            <li
                                                key={i}
                                                onClick={() => setApplySpecialtyFilter(specialty.name)}
                                                className="cursor-pointer hover:bg-gray-300 transition-colors border-b border-gray-300"
                                            >
                                                <div className='py-2 px-4 flex justify-between items-center'>
                                                    <div className="min-w-8">{applySpecialtyFilter === specialty.name ? <RadioButtonChecked /> : <RadioButton />}</div>
                                                    <div className='w-2/3 flex justify-between items-center break-words'>
                                                        {specialty.name}
                                                    </div>
                                                    <div className='w-1/3 flex items-center justify-end'>
                                                        <ComplianceIndicator passed={specialty.compliance} />
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    ) : (
                                        <li>
                                            <div className='py-2 px-4 text-gray-500'>No specialties available</div>
                                        </li>
                                    )}
                                </ul>}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default observer(SpecialtyFilterTable);
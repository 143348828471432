import auth from './auth';
import {dataValue, GeographyDistance, Membership, MyFavorite, SavedQuery, SearchCanvas} from './data-types';
import checkAccess, {SiteFeature} from './check-access';
import {getAccessToken} from './entra-auth/entra-auth';
import {msalInstance} from './entra-auth/entra-auth-provider';

const protocol = String(process.env.REACT_APP_API_BASE).startsWith('local.torch') ? 'http://' : 'https://';
const apiBase = protocol + process.env.REACT_APP_API_BASE;
const apiFFBase = protocol + 'api-ff.torchinsight.com';
const DOWNLOADABLE_MIME_TYPES = ['text/csv', 'text/plain', 'application/zip'];

const appsFF = [
    'quantum'
];

export async function authHeader(forceEntraAuth = false): Promise<HeadersInit> {
    const authHeader: HeadersInit = {};
    if (auth.token)
        authHeader['Authorization'] = 'Bearer ' + auth.token;

    const account = msalInstance.getActiveAccount(); // Get the active account
    if (account || forceEntraAuth) {
        const entraAccessToken = await getAccessToken(account);
        if (entraAccessToken)
            authHeader['Entra-Authorization'] = 'Bearer ' + entraAccessToken;
    }

    return authHeader;
}

function determineAPIHost(path = '') {
    let host = apiBase;
    const ffFlag = ffEnv.useFFEnv();
    if (checkAccess(SiteFeature.Admin) &&
        ffFlag &&
        appsFF.some(ff => path.includes(ff))) {
        host = apiFFBase;
    }
    if (process.env.NODE_ENV === 'test')
        host = 'https://test-api';

    return host;
}

const jsonHeader = {'Content-Type': 'application/json; charset=UTF-8'};

async function makeRequest(method: string, path: string, signal?: AbortSignal, payload?: object, forceEntraAuth = false, customHeaders?: Array<{[key: string]: string}>, apiBase?: string) {
    const authHeaders = await authHeader(forceEntraAuth);
    let headers = {...authHeaders, ...jsonHeader};
    if (customHeaders && customHeaders.length > 0)
        headers = {...headers, ...customHeaders.reduce((acc, h) => ({...acc, ...h}), {})};
    let host = apiBase || determineAPIHost(path);
    const response = await fetch(host + path, {
        method,
        body: payload && JSON.stringify(payload),
        headers: headers,
        signal: signal,
    });

    return await handleResponse(response);
}

async function handleResponse(response: Response) {
    if (response.status === 401)
        throw new auth.InvalidAuthError();
    if (response.status === 204)
        return response;
    if (response.status !== 204) {
        if (response.headers.get('content-type') === 'application/jwt') {
            const text = await response.text();
            if (!response.ok)
                throw new Error(text);
            return text;
        } else if (DOWNLOADABLE_MIME_TYPES.some(contentType => response.headers.get('content-type') === contentType)) {
            const blob = await response.blob();
            return blob;
        } else {
            const json = await response.json();
            if (!response.ok)
                throw new Error(json.errorMessage);
            return json;
        }
    }

    return response;
}

async function post(path: string, payload: object, signal?: AbortSignal, customHeaders?: Array<{[key: string]: string}>, apiBase?: string, forceEntraAuth = false) {
    return makeRequest('POST', path, signal, payload, forceEntraAuth, customHeaders, apiBase);
}

async function put(path: string, payload: object, signal?: AbortSignal, forceEntraAuth = false) {
    return makeRequest('PUT', path, signal, payload, forceEntraAuth);
}

async function patch(path: string, payload: object, signal?: AbortSignal, forceEntraAuth = false) {
    return makeRequest('PATCH', path, signal, payload, forceEntraAuth);
}

async function del(path: string, signal?: AbortSignal, forceEntraAuth = false) {
    return makeRequest('DELETE', path, signal, undefined, forceEntraAuth);
}

async function get(path: string, signal?: AbortSignal, customHeaders?: Array<{[key: string]: string}>, forceEntraAuth = false) {
    return makeRequest('GET', path, signal, undefined, forceEntraAuth, customHeaders);
}

const ffEnv = {
    ffEnvKey: 'useFF',
    setFFEnv: function() {
        if (auth.token) {
            sessionStorage.clear();
            sessionStorage.setItem(ffEnv.ffEnvKey, 'true');
        }
    },
    clearFFEnv: function() {
        sessionStorage.clear();
    },
    useFFEnv: function() {
        return Boolean(sessionStorage.getItem(ffEnv.ffEnvKey));
    },
    toggleEnv: function(): boolean {
        if (ffEnv.useFFEnv())
            ffEnv.clearFFEnv();
        else
            ffEnv.setFFEnv();

        return ffEnv.useFFEnv();
    }
};

const analytics = {
    save: async (eventName: string, payload: object) => post('/v2/a/in', {name: eventName, ...payload}),
    org: {
        checkAccess: async (orgId: number, signal: AbortSignal) => get(`/v2/analytics/org/${orgId}/checkAccess`, signal)
    }
};

const savedMarkets = {
    listByUser: async (signal: AbortSignal) => get('/v2/vis/markets?for=user', signal),
    listByUserOrgs: async (signal: AbortSignal) => get('/v2/vis/markets?for=userOrgs', signal),
};

const quantum = {
    execute: async (obj: object, signal: AbortSignal, streamResponse?: boolean) => post('/v2/quantum/data/execute', obj, signal, streamResponse ? [{'Stream-Type': 'chunked'}] : undefined),
    metadata: async (obj: object, signal: AbortSignal) => post('/v2/quantum/metadata', obj, signal),
    datasetMetadata: async (dataset: string, signal: AbortSignal) => get('/v2/quantum/metadata/' + dataset, signal),
    datasetInfo: async (dataset: string, signal: AbortSignal) => get(`/v2/quantum/metadata/${dataset}/info`, signal),
    batchDatasetMetadata: async (datasets: Array<string>, signal: AbortSignal) => post('/v2/quantum/metadata/batch', {datasets}, signal),
    batchDatasetInfo: async (datasets: Array<string>, signal: AbortSignal) => post('/v2/quantum/metadata/batch/info', {datasets}, signal),
};

const accessRoles = {
    list: async (signal: AbortSignal) => get('/v2/user/access-roles', signal),
    create: async (accessRole: any) => post('/v2/user/access-roles', accessRole),
    update: async (accessRole: any) => put(`/v2/user/access-roles/${accessRole.id}`, {name: accessRole.name, description: accessRole.description}),
    delete: async (id: number) => del(`/v2/user/access-roles/${id}`),
};

const modules = {
    list: async (signal: AbortSignal) => get('/v2/user/modules', signal),
    create: async (module: any) => post('/v2/user/modules', module),
    update: async (module: any) => put(`/v2/user/modules/${module.id}`, module),
    delete: async (id: number) => del(`/v2/user/modules/${id}`),
    datasets: {
        list: async (signal: AbortSignal) => get('/v2/user/modules/datasets', signal),
        create: async (datasetModule: any) => post('/v2/user/modules/datasets', datasetModule),
        get: async (dataset: string) => get(`/v2/user/modules/datasets/${dataset}`),
        update: async (datasetModule: any) => put(`/v2/user/modules/datasets/${datasetModule.id}`, datasetModule),
        delete: async (id: number) => del(`/v2/user/modules/datasets/${id}`)
    },
    canvas: {
        list: async (signal: AbortSignal) => get('/v2/user/modules/canvas', signal),
        create: async (canvasModule: any) => post('/v2/user/modules/canvas', canvasModule),
        update: async (canvasModule: any) => put(`/v2/user/modules/canvas/${canvasModule.id}`, canvasModule),
        delete: async (id: number) => del(`/v2/user/modules/canvas/${id}`)
    },
};

const users = {
    list: async (signal: AbortSignal) => get('/v2/user/users', signal),
    create: async (user: any) => post('/v2/user/users', user),
    modify: async (user: any) => patch(`/v2/user/users/${user.id}`, user),
    update: async (user: any) => put(`/v2/user/users/${user.id}`, user),
    delete: async (id: number) => del(`/v2/user/users/${id}`),
    datasets: {
        list: (signal: AbortSignal) => get('/v2/user/user-datasets', signal),
    },
    proxy: {
        proxy: async (id: number) => post (`/v2/user/authenticate/proxy/${id}`, {}),
    },
    passwordReset: {
        reset: (email: string) => post(`/v2/user/password-reset`, {email}),
        check: (email: string, code: string) => post(`/v2/user/password-reset/check`, {email, code}),
        change: (email: string, code: string, password: string) => post(`/v2/user/password-reset/change`, {email, code, password})
    },
    join: {
        checkCode: (code: string) => get(`/v2/user/join/${code}`),
        setPassword: (code: string, password: string) => post(`/v2/user/join/${code}`, {password})
    }
};

const organizations = {
    list: async (signal: AbortSignal) => get('/v2/user/organizations?all=true', signal),
    listByUser: async(signal: AbortSignal) => get('/v2/user/organizations', signal),
    get: async (id: number, signal: AbortSignal) => get(`/v2/user/organizations/${id}`, signal),
    create: async (org: any) => post('/v2/user/organizations', org),
    update: async (org: any) => put(`/v2/user/organizations/${org.id}`, org),
    modify: async (org: any) => patch(`/v2/user/organizations/${org.id}`, org),
    delete: async (orgId: number) => del(`/v2/user/organizations/${orgId}`),
    users: {
        add: async (orgId: number, userId: number) => patch(`/v2/user/organizations/${orgId}/users/${userId}`, {}),
        get: async (orgId: number, userId: number, signal: AbortSignal) => get(`/v2/user/organizations/${orgId}/users/${userId}`, signal),
        list: async (orgId: number, signal: AbortSignal) => get(`/v2/user/organizations/${orgId}/users`, signal),
        listInvitees: async (orgId: number, signal: AbortSignal) => get(`/v2/user/organizations/${orgId}/invitees`, signal),
        invite: async (orgId: number, invitation: object) => patch(`/v2/user/organizations/${orgId}/users`, invitation),
        create: async (orgId: number, userId: number | string) => patch(`/v2/user/organizations/${orgId}/users/${userId}`, {}),
        delete: async (orgId: number, userId: number) => del(`/v2/user/organizations/${orgId}/users/${userId}`),
        update: async (membership: Membership) => put(`/v2/user/organizations/${membership.orgId}/users/${membership.userId}`, membership),
        passwordReset: async (orgId: number, userId: number) => post(`/v2/user/organizations/${orgId}/users/${userId}/password-reset`, {}),
    },
    domains: {
        list: async (orgId: number, signal: AbortSignal) => get(`/v2/user/organizations/${orgId}/domains`, signal),
        create: async (orgId: number, domain: dataValue) => post(`/v2/user/organizations/${orgId}/domains`, {domain}),
        delete: async (orgId: number, domainId: number) => del(`/v2/user/organizations/${orgId}/domains/${domainId}`)
    },
    proxy: async (id: number) => post(`/v2/user/authenticate/refresh?orgId=${id}`, {})
};

const dashboards = {
    list: async (signal: AbortSignal) => get('/v2/vis/dashboards', signal),
    listByUser: async (signal: AbortSignal) => get('/v2/vis/dashboards?for=user', signal),
    listByUserOrgs: async (signal: AbortSignal) => get('/v2/vis/dashboards?for=userOrgs', signal),
    create: async (dashboard: any) => post('/v2/vis/dashboards', dashboard),
    update: async (dashboard: any) => put(`/v2/vis/dashboards/${dashboard.id}`, dashboard),
    modify: async (dashboard: any) => patch(`/v2/vis/dashboards/${dashboard.id}`, dashboard),
    delete: async (id: number) => del(`/v2/vis/dashboards/${id}`),
    tags: {
        list: async () => [{id: 1, name: 'Geography'}, {id: 2, name: 'Topics'}, {id: 3, name: 'Entities and Organizations'}, {id: 4, name: 'Other'}]
    }
};

const mapConfigs = {
    list: async (signal: AbortSignal) => get('/v2/vis/map-config', signal),
    listByUser: async (signal: AbortSignal) => get('/v2/vis/map-config?for=user', signal),
    listByUserOrgs: async (signal: AbortSignal) => get('/v2/vis/map-config?for=userOrgs', signal),
    create: async (mapConfig: any) => post('/v2/vis/map-config', mapConfig),
    update: async (mapConfig: any) => put(`/v2/vis/map-config/${mapConfig.id}`, mapConfig),
    delete: async (id: number) => del(`/v2/vis/map-config/${id}`),
};

const visualizations = {
    listByUser: async (signal: AbortSignal) => get('/v2/vis/visualizations?for=user', signal),
    listByUserOrgs: async (signal: AbortSignal) => get('/v2/vis/visualizations?for=userOrgs', signal)
};

const userDatasets = {
    list: async (signal: AbortSignal) => get('/v2/user/user-datasets', signal),
    create: async (dataset: any) => post('/v2/user/user-datasets', dataset),
    modify: async (dataset: any) => patch(`/v2/user/user-datasets/${dataset.id}`, dataset),
    update: async (dataset: any) => put(`/v2/user/user-datasets/${dataset.id}`, dataset),
    delete: async (id: number | string) => del(`/v2/user/user-datasets${id}`)
};

const datasetPermissions = {
    validate: async (dataset: string, signal?: AbortSignal) => post('/v2/user/validate/dataset', {id: dataset, accessType: 1}, signal),
    validateAll: async (datasets: string[], signal?: AbortSignal) => post('/v2/user/validate-all/dataset', {ids: datasets, accessType: 1}, signal),
};

const savedQuery = {
    list: {
        all: async (signal: AbortSignal) => get('/v2/cogniboard/saved-query', signal),
        forUser: async (signal: AbortSignal) => get('/v2/cogniboard/saved-query?for=user', signal),
    },
    get: async (queryId: number, signal: AbortSignal) => get(`/v2/cogniboard/saved-query/${queryId}`, signal),
    create: async (savedQuery: SavedQuery) => post('/v2/cogniboard/saved-query', savedQuery),
    modify: async (savedQuery: SavedQuery) => patch(`/v2/cogniboard/saved-query/${savedQuery.id}`, savedQuery),
    delete: async (id: number) => del(`/v2/cogniboard/saved-query/${id}`)
};

const hcIndex = {
    sections: async (signal: AbortSignal) => get('/v2/cogniboard/healthcare-index/section', signal),
    getById: async (id: number, signal: AbortSignal) => get(`/v2/cogniboard/healthcare-index/entry/${id}`, signal),
    getByShortName: async (shortName: string, signal: AbortSignal) => get(`/v2/cogniboard/healthcare-index/entry/short-name/${shortName}`, signal),
    getBlindProd: async (signal: AbortSignal) => get('/v2/cogniboard/healthcare-index/blind-production/entries', signal),
};

const insightContainer = {
    list: {
        all: async (signal: AbortSignal) => get('/v2/cogniboard/insight-container', signal),
        forUser: async (signal: AbortSignal) => get('/v2/cogniboard/insight-container?for=user', signal),
    },
    get: async (id: number, signal: AbortSignal) => get(`/v2/cogniboard/insight-container/${id}`, signal),
    create: async (insightContainer: any) => post('/v2/cogniboard/insight-container', insightContainer),
    delete: async (id: number) => del(`/v2/cogniboard/insight-container/${id}`),
};

const canvas = {
    list: async (signal: AbortSignal) => get('/v2/cogniboard/canvas', signal),
    listByUser: async (signal: AbortSignal) => get('/v2/cogniboard/canvas?for=user', signal),
    get: async (id: number, signal?: AbortSignal) => get(`/v2/cogniboard/canvas/${id}`, signal),
    getDraft: async (id: number, signal?: AbortSignal) => get(`/v2/cogniboard/canvas/draft/${id}`, signal),
    getByTitle: async (title: string, signal?: AbortSignal) => get(`/v2/cogniboard/canvas/title/${title}`, signal),
    getByTitleDraft: async (title: string, signal?: AbortSignal) => get(`/v2/cogniboard/canvas/title/draft/${title}`, signal),
    getCanvasVersions: async (id: number, signal?: AbortSignal) => get(`/v2/cogniboard/canvas/versions/${id}`, signal),
    getCanvasVersion: async (id: number, signal?: AbortSignal) => get(`/v2/cogniboard/canvas/version/${id}`, signal),
    create: async (canvas: any) => post('/v2/cogniboard/canvas', canvas),
    update: async (canvas: any) => put(`/v2/cogniboard/canvas/${canvas.id}`, canvas),
    publish: async (canvas: any) => put(`/v2/cogniboard/canvas/publish/${canvas.id}`, canvas),
    delete: async (id: number) => del(`/v2/cogniboard/canvas/${id}`),
    listSections: async (signal: AbortSignal) => get('/v2/cogniboard/canvas/section', signal),
    getLink: async (dataset: string, signal: AbortSignal) => get(`/v2/cogniboard/canvas/link/${dataset}`, signal),
};

const download = {
    list: async (signal: AbortSignal) => get('/v2/data/downloads', signal),
    trigger: async () => post('/v2/downloads/trigger', {}),
    user: {
        request: async (query: any) => post('/v2/query/ext-query/async-download', query),
        list: async (signal: AbortSignal, all = false) => get(`/v2/downloads/user-downloads${all ? '?all=true' : ''}`, signal)
    }
};

const search: Record<string, any> = {
    metadata: async (query: string, signal: AbortSignal) => get(`/v2/search/metadata?q=${encodeURIComponent(query)}`, signal),
    entities: async (query: string, state: string, city: string, signal: AbortSignal) => get(`/v2/search/entity?q=${encodeURIComponent(query)}` +
        `${(state && '&state=' + encodeURIComponent(state))}` +
        `${city && '&city=' + encodeURIComponent(city)}`, signal),
    entitiesByType: async (query: string, type: string, state: string, city: string, signal: AbortSignal) => get(`/v2/search/entity?q=${encodeURIComponent(query)}` +
        `${type && '&entityType=' + encodeURIComponent(type)}` +
        `${state && '&state=' + encodeURIComponent(state)}` +
        `${city && '&city=' + encodeURIComponent(city)}`, signal),
    regions: async (query: string, signal: AbortSignal) => get(`/v2/search/region?q=${encodeURIComponent(query)}`, signal),
    tools: async (query: string, signal: AbortSignal) => get(`/v2/search/tool?q=${encodeURIComponent(query)}`, signal),
    dashboards: async (query: string, signal: AbortSignal) => get(`/v2/search/dashboard?q=${encodeURIComponent(query)}`, signal),
    states: async (query: string, signal: AbortSignal) => get(`/v2/search/states?q=${encodeURIComponent(query)}`, signal),
    cities: async (query: string, signal: AbortSignal) => get(`/v2/search/cities?q=${encodeURIComponent(query)}`, signal)
};

const siteSearch = {
    searchAll: async (query: string, signal: AbortSignal, numRows?: number) => get(`/v2/site-search/search?q=${encodeURIComponent(query)}${numRows ? `&numRows=${numRows}` : ''}`, signal),
    searchHCI: async (query: string, signal: AbortSignal) => get(`/v2/site-search/search/hci?q=${encodeURIComponent(query)}`, signal),
    searchTool: async (query: string, signal: AbortSignal) => get(`/v2/site-search/search/tools?q=${encodeURIComponent(query)}`, signal),
    listCanvasIndexes: async (signal: AbortSignal) => get('/v2/site-search/', signal),
    searchCanvas: async (searchCanvas: SearchCanvas, signal: AbortSignal) => post(`/v2/site-search/search/canvas`, searchCanvas, signal)
};

const siteConfig = {
    get: async (name: string, signal: AbortSignal) => get(`/v2/vis/config/${name}`, signal)
};

const contentAccess = {
    list: async (signal: AbortSignal) => get('/v2/user/modules/content-access', signal),
    get: async (id: number, sourceType: string, signal: AbortSignal) => get(`/v2/modules/content-access/${sourceType}/${id}`, signal),
    create: async (contentAccess: any) => post('/v2/user/modules/content-access', contentAccess),
    update: async (contentAccess: any) => put(`/v2/user/modules/content-access/${contentAccess.id}`, contentAccess),
    delete: async (contentAccessId: number) => del(`/v2/user/modules/content-access/${contentAccessId}`)
};

const geography = {
    list: async (shape: string, signal: AbortSignal) => get(`/v2/geography/${shape}`, signal),
    listAll: async (signal: AbortSignal) => get('/v2/geography/', signal),
    get: async (shape: string, year: string, size: string, signal: AbortSignal) => get(`/v2/geography/${shape}/${year}/${size}`, signal),
    getPartial: async (shape: string, year: string, size: string, geoIds: Array<string>, signal: AbortSignal) => post(`/v2/geography/${shape}/${year}/${size}`, {'geo_ids': geoIds}, signal),
    getWithinDistance: async (shape: string, year: string, size: string, body: GeographyDistance, signal: AbortSignal) => post(`/v2/geography/${shape}/${year}/${size}/distance`, body, signal)
};

const content = {
    myFavorites: {
        listAll: async (signal: AbortSignal) => get('/v2/content/favorites', signal),
        listTop: async (signal: AbortSignal) => get('/v2/content/favorites/top', signal),
        create: async (myFavorite: MyFavorite) => post('/v2/content/favorites', myFavorite),
        delete: async (myFavoriteId: number) => del(`/v2/content/favorites/${myFavoriteId}`),
    },
    spotlight: {
        list: async (signal: AbortSignal) => get('/v2/content/spotlight', signal),
        listN: async (number: number, signal: AbortSignal) => get(`/v2/content/spotlight/date/${number}`, signal),
    }
};


//network optimization
const networkOptimization = {
    fetchPatientGeoJson: async (filters: Record<string, string>, runId: number, revisionFlag: boolean, distanceCalcType: string, signal: AbortSignal) => post(`/v2/network-optimization/adequacy/patient/${runId}`, {...filters, revision_flag: revisionFlag, distance_calc_type: distanceCalcType}, signal, [{'Content-Type': 'application/geo-json'}], undefined, true),
    fetchPatientPopulationGeoJson: async (filters: Record<string, string>, runId: number, revisionFlag: boolean, distanceCalcType: string, signal: AbortSignal) => post(`/v2/network-optimization/adequacy/patient/population/${runId}`, {...filters, revision_flag: revisionFlag, distance_calc_type: distanceCalcType}, signal, [{'Content-Type': 'application/geo-json'}], undefined, true),
    fetchProviderGeoJson: async (filters: Record<string, string>, runId: number, revisionFlag: boolean, signal: AbortSignal) => post(`/v2/network-optimization/adequacy/provider/${runId}`, {...filters, revision_flag: revisionFlag}, signal, [{'Content-Type': 'application/geo-json'}], undefined, true),
    fetchProviderReport: async (filters: Record<string, string>, id: number, revisionFlag: boolean, signal: AbortSignal) => post(`/v2/network-optimization/adequacy/provider/${id}`, {...filters, revision_flag: revisionFlag}, signal, undefined, undefined, true),
    fetchProviderRoster: async (runId: number, signal: AbortSignal) => get(`/v2/network-optimization/roster/provider/${runId}`, signal, undefined, true),
    fetchGeographyFilters: async (id: number, signal?: AbortSignal) => get(`/v2/network-optimization/adequacy/geographies/${id}`, signal, undefined, true),
    fetchProviders: async (filters: Record<string, string>, id: number, revisionFlag: boolean, signal: AbortSignal) => post(`/v2/network-optimization/providers/${id}`, {...filters, revision_flag: revisionFlag}, signal, undefined, undefined, true),
    fetchSpecialtyReport: async (filters: Record<string, string>, id: number, revisionFlag: boolean, distanceCalcType: string, signal?: AbortSignal) => post(`/v2/network-optimization/adequacy/specialty/${id}`, {...filters, revision_flag: revisionFlag, distance_calc_type: distanceCalcType}, signal, undefined, undefined, true),
    recalculateRevision: async (id: number, specialtyCode: string, county: string, signal: AbortSignal) => post(`/v2/network-optimization/adequacy/recalculate/${id}/${specialtyCode}`, {county}, signal, undefined, undefined, true),
    fetchNavItems: async (): Promise<{ label: string; path: string; useRosterID: boolean }[]> => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([
                    {label: 'My Files', path: 'my-files', useRosterID: false},
                    {label: 'Time and Distance Access', path: 'time-distance-access', useRosterID: true},
                    {label: 'Roster', path: 'roster', useRosterID: true},
                ]);
            }, 1000); 
        });
    },
    downloadProviderRoster: async (id = 1, signal: AbortSignal) => get(`/v2/network-optimization/roster/provider/${id}/providers`, signal, [{'Content-Type': 'text/plain'}], true),
    downloadFacilityRoster: async (id = 1, signal: AbortSignal) => get(`/v2/network-optimization/roster/provider/${id}/facilities`, signal, [{'Content-Type': 'text/plain'}], true),
    downloadZipRoster: async (id = 1, signal: AbortSignal) => get(`/v2/network-optimization/roster/provider/${id}/zip`, signal, [{'Content-Type': 'application/zip'}], true),
    updateProviderRoster: async (id: number, specialtyCode: string, providersToAdd: Array<string>, providersToRemove: Array<string>, signal: AbortSignal) => {
        const requestBody: any = {};
        if (providersToAdd.length > 0) {
            requestBody.providers_to_add = providersToAdd;
        }
        
        if (providersToRemove.length > 0) {
            requestBody.providers_to_remove = providersToRemove;
        }
        return post(`/v2/network-optimization/roster/provider-roster/${id}/${specialtyCode}`, requestBody, signal, undefined, undefined, true);
    },
    listProviderRosters: async (signal: AbortSignal) => get('/v2/network-optimization/roster/user-provider-rosters', signal, undefined, true),
    getUserProviderRoster: async (id: number, signal: AbortSignal) => {
        const rosters = await networkOptimization.listProviderRosters(signal);
        return rosters.find((roster: any) => roster.rosterId === id.toString());
    },
    resyncProviderRosterRevision: async (rosterId: number, specialtyCode: string, signal: AbortSignal) => get(`/v2/network-optimization/roster/provider-roster/revision-sync/${rosterId}/${specialtyCode}`, signal, undefined, true),
};
export {
    analytics,
    savedMarkets,
    quantum,
    accessRoles,
    modules,
    users,
    organizations,
    dashboards,
    mapConfigs,
    visualizations,
    userDatasets,
    datasetPermissions,
    savedQuery,
    hcIndex,
    insightContainer,
    canvas,
    ffEnv,
    download,
    search,
    siteSearch,
    siteConfig,
    contentAccess,
    geography,
    determineAPIHost,
    content,
    networkOptimization
};
// types.ts
export type GeographyFilterOptions = {
    code: string;
    state: string;
    state_ssa: string
    counties: Array<{
        county_name: string;
        county_ssa: string;
        fips: string;
        state: string;
        year_key: number;
    }>
};

export type AppliedGeographyFilter = {
    state: string;
    county: string;
};
export type ComplianceScore = any; 
export type RunId = number;

export type FetchGeoJsonResponse = GeoJSON.FeatureCollection; 
export type FetchProvidersResponse = any[];
export type FetchComplianceResponse = Array<any>;
export type FetchRunIdResponse = number | null;

export const DISTANCE_CALC_TYPES = [
    {name: 'Drivetime', value: 'actual'},
    {name: 'Physical', value: 'euclidean'},
];

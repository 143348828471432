import {ReactNode, useContext, useRef} from 'react';
import logoBlue from '../images/logo-blue.svg';
import {Link, useNavigate} from 'react-router-dom';
import classNames from '../../lib/class-names';
import HeaderButton from './header-button';
import ProfileMenu from './profile-menu';
import {HeaderContext} from './context';
import './header.scss';
import {Help, Notebook, UserAvatar} from '@carbon/icons-react';
import HeaderSearch from './header-search';
import auth from '../../lib/auth';
import SiteName from '../SiteName';

export type NavbarProps = {
    className?: string,
    siteNameOverride?: string,
    showLogo?: boolean,
    showOrg?: boolean,
    navItems?: {label: ReactNode, path: string}[]
}

export default function Header({className, siteNameOverride, showLogo = true, showOrg = false, navItems = []}: NavbarProps) {
    const navMenuRef = useRef<HTMLButtonElement>(null);
    const classes = classNames('header', className);
    const headerStore = useContext(HeaderContext);
    const navigate = useNavigate();

    const shouldHideButtons = headerStore.hideExtraButtons; 

    return (
        <nav className={classes}>
            <div className='pl-4 h-12 w-full'>
                <div className='flex items-center h-12 w-full gap-4 pr-4'>
                    <div className='flex items-center'>
                        <Link to='/' className='hover:no-underline text-gray-300'>
                            {showLogo && <img
                                className='inline h-8 w-auto mr-6'
                                src={logoBlue}
                                alt='Torch Insight'
                            />}
                            <span className='hover:no-underline'>
                                Milliman&nbsp;<span className='font-bold text-gray-100'>{siteNameOverride ? siteNameOverride : <SiteName />}</span>
                            </span>
                        </Link>
                    </div>
                    {showOrg && <>
                        <div className='w-px bg-gray-300 h-6'></div>
                        <div className='text-gray-300'>{auth.principal?.orgs[0].name}</div>
                    </>}
                    {navItems.length && <>
                        <div className='w-px bg-gray-300 h-6'></div>
                        {navItems.map((item, index) => (
                            <span key={index}>
                                <Link className='text-gray-300 text-sm hover:font-semibold' to={item.path}>
                                    {item.label}
                                </Link>
                            </span>
                        ))}
                    </>}
                    <div className='block flex-grow' />
                    {auth.principal && <div className='flex items-center h-full'>
                        {!shouldHideButtons && <HeaderSearch />} 
                        <div className='h-full relative flex'>
                            {!shouldHideButtons && (
                                <>
                                    <HeaderButton id="my-content" icon={<Notebook/>} onClick={() => {headerStore.showMyContent = !headerStore.showMyContent; headerStore.setShowMyContentPreference(headerStore.showMyContent);}} highlight={headerStore.showMyContent} tooltipName="My Content" />
                                    <HeaderButton id="help-section" icon={<Help/>} onClick={() => navigate('/docs/api/')} tooltipName="Resources"/>
                                </>
                            )}
                        </div>
                        <HeaderButton id="user-menu" icon={<UserAvatar/>} popupRef={navMenuRef} tooltipName="My Account" />
                    </div>}
                </div>
            </div>
            <ProfileMenu popupRef={navMenuRef} />
        </nav>
    );
}

import React, {useState} from 'react';

type Tab = {
    label: string;
    content: React.ReactNode;
};

type TabsProps = {
    tabs: Tab[];
    onClick?: (index: number) => void;
};

const Tabs: React.FC<TabsProps> = ({tabs, onClick}) => {
    const [activeTab, setActiveTab] = useState(0);

    function setTab(index: number) {
        setActiveTab(index);
        onClick && onClick(index);
    }

    return (
        <div className="tabs-component">
            <div className="tab-buttons flex border-b pl-12">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 border-b-2 cursor-pointer ${activeTab === index ? 'border-blue-500 font-bold text-gray-900' : 'border-background-gray-200 text-gray-500 hover:text-gray-900'}`}
                        onClick={() => setTab(index)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            <div className="tab-content">
                <div>
                    {tabs[activeTab].content}
                </div>
            </div>
        </div>
    );
};

export default Tabs;

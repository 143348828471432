import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    Layer,
    Tooltip,
    Modal
} from '@carbon/react';
import React, {useEffect, useContext, useState, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {NetworkContext} from '../lib/context';
import ComplianceIndicator from './compliance-indicator';
import {Renew, Information} from '@carbon/icons-react';
import './compliance-score.scss';
import Spinner from '../../../components/spinner';

type Props = {
    compactView?: boolean
}

function ComplianceScore(props: Props) {
    const {compactView} = props;
    const networkOptimizationStore = useContext(NetworkContext);
    const [loading, setLoading] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [error, setError] = useState<string | null>(null);
    const {complianceScores, revisionCount, loadingRevisionRecalculation} = networkOptimizationStore;

    useEffect(() => {
        const fetchComplianceScores = async () => {
            const controller = new AbortController();
            setLoading(true);
            try {
                await networkOptimizationStore.loadSpecialtiesData(controller);
            } catch (err) {
                setError('Failed to refresh compliance scores.');
            } finally {
                setLoading(false);
            }
        };

        if (!loadingRevisionRecalculation) {
            fetchComplianceScores();
        }
    }, [revisionCount, loadingRevisionRecalculation, networkOptimizationStore.distanceCalcType]);

    const {selectedScore, headers} = useMemo(() => {
        try {
            const selectedScore = complianceScores?.find((score: { name: string }) => score.name === networkOptimizationStore.specialtyFilter)
                || networkOptimizationStore.calculateGeneralComplianceScores();
            const headers = Object.keys(selectedScore.scores)
                .map((key) => selectedScore.scores[key].name)
                .filter((name) => name !== 'Min Required' || !networkOptimizationStore.specialtyFilter);
            
            return {selectedScore, headers};
        } catch (err) {
            setError('Error generating compliance scores.');
            return {selectedScore: null, headers: []};
        }
    }, [complianceScores, networkOptimizationStore.specialtyFilter, networkOptimizationStore.providerGeoJson]);

    const getTooltipLabel = (header: string): string => {
        const isSpecialtySelected = !!networkOptimizationStore.specialtyFilter;
    
        const tooltipLabels: { [key: string]: string } = {
            'Time': 'The percentage of the provider roster for the selected specialty that meets the driving time standard for the patient population.',
            'Distance': 'The percentage of the provider roster for the selected specialty that meets the driving distance standard for the patient population.',
            '# of Provider Locations': 'The number of providers in the selected specialty that are included in the current roster.',
            '# of Beds': 'The number of beds in the selected Hospital.',
            'Total Compliance': isSpecialtySelected
                ? '✓ indicates the current roster is compliant with the CMS adequacy guidelines. ✗ indicates the plan is not in compliance with the CMS adequacy guidelines. Add providers from the table below to meet compliance standards.'
                : 'The number of specialties in your roster that meet the time and distance standard divided by the number of specialties that are included in the CMS adequacy standard.'
        };
    
        return tooltipLabels[header] || 'No tooltip available'; 
    };

    function renderDefaultView() {
        const hasSpecialtySelected = networkOptimizationStore.specialtyFilter;
    
        const additionalHeaders = hasSpecialtySelected ? [] : ['Invisible 1', 'Invisible 2'];
    
        const additionalScores = hasSpecialtySelected ? [] : [
            {name: '', value: '', compliant: null},
            {name: '', value: '', compliant: null}
        ];
    
        const allHeaders = [...additionalHeaders, ...headers];
        const allScores = [...additionalScores, ...selectedScore.scores.slice(0, 4)];

        return (
            <Layer className='h-full'>
                <div className="compliance-score bg-white h-full flex flex-col relative overflow-visible">
                    <h1 className="text-lg font-semibold py-4 pl-4 m-0">
                        {hasSpecialtySelected
                            ? `Estimated Total Compliance - ${networkOptimizationStore.specialtyFilter}`
                            : 'Estimated Total Compliance'}
                    </h1>
    
                    <TableContainer>
                        <DataTable
                            rows={[selectedScore]}
                            headers={allHeaders.map((header) => ({
                                key: header,
                                header: header
                            }))}
                        >
                            {({headers}) => (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => {
                                                const headerLabel = header.header as string;
                                                return (
                                                    <TableHeader
                                                        key={header.key}
                                                        className={`bg-gray-100 ${headerLabel.startsWith('Invisible') ? 'w-1/12' : 'w-3/12'}`}  
                                                    >
                                                        {headerLabel.startsWith('Invisible') ? null : (
                                                            <div className="flex items-center justify-end gap-1">
                                                                <span>{headerLabel}</span>
                                                                <Tooltip label={getTooltipLabel(headerLabel)} align="top" autoAlign>
                                                                    <button type="button" aria-label="Information">
                                                                        <Information size={14} className="hover:text-blue-500" />
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                        )}
                                                    </TableHeader>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {allScores.map((score: { name: string, value: number, compliant: boolean, cssClasses?: string }, index: number) => (
                                                <TableCell
                                                    key={index}
                                                    className="w-3/12"  
                                                >
                                                    <div className="flex justify-end items-center space-x-3">
                                                        <span>{score.value}</span>
                                                        {score.compliant !== null && <ComplianceIndicator passed={score.compliant} />}
                                                    </div>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )}
                        </DataTable>
                    </TableContainer>
    
                </div>
            </Layer>
        );
    }
    
    
    
    function renderCompactView() {
        const compactScores = selectedScore.scores.slice(0, 4);
        return (
            <div className="bg-white">
                <h1 className="text-lg font-semibold py-4 pl-4 m-0">
                    {networkOptimizationStore.specialtyFilter
                        ? `Estimated Total Compliance - ${networkOptimizationStore.specialtyFilter}`
                        : 'Estimated Total Compliance'}
                </h1>
                <div className="w-full border-b border-gray-300 flex justify-end">
                    <button
                        disabled={networkOptimizationStore.disableRecalculateRevision}
                        className={`primary ${networkOptimizationStore.disableRecalculateRevision ? 'disabled' : ''}`}
                        onClick={() => networkOptimizationStore.recalculateRevision(new AbortController())}>
                        <span className="mr-2">Re-Calculate</span>
                        <Renew />
                    </button>
                </div>
                <ul className="flex flex-col">
                    {compactScores.map((score: { name: string, value: number, compliant: boolean, cssClasses?: string }, index: number) => (
                        <li key={index} className={`flex justify-between items-center border-b border-gray-300 p-2 ${score.cssClasses}`}>
                            <div className="flex items-center">
                                <span>{score.name}</span>
                                <Tooltip label={getTooltipLabel(score.name)} align="top" autoAlign>
                                    <button className="sb-tooltip-trigger ml-2" type="button" aria-label="Information">
                                        <Information className="hover:text-blue-500" />
                                    </button>
                                </Tooltip>
                            </div>
                            <div className="w-1/4 flex justify-end space-x-3 items-center">
                                <span>{score.value}</span>
                                {score.compliant !== null && <ComplianceIndicator passed={score.compliant} />}
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="revision-modal">
                    <Modal open={networkOptimizationStore.loadingRevisionRecalculation} modalLabel="Processing your data" passiveModal preventCloseOnClickOutside>
                        <div className='w-full p-4 flex space-x-8 items-center'>
                            <Spinner />
                            <div>
                                <p>Thank you for your patience! Recalculating your network adequacy may take a few minutes. <br />
                                    <strong>Please avoid refreshing your browser.</strong></p>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }

    if (loading || !complianceScores || Object.keys(complianceScores).length === 0)
        return networkOptimizationStore.readyToLoadData() ? <div className="flex items-center space-x-3"> <Spinner /> <span className="block">Loading compliance scores...</span></div> : null;

    return compactView ? renderCompactView() : renderDefaultView();
}

export default observer(ComplianceScore);
